import React, { Fragment, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { Typography, FormControl, TextField, Divider, Button, useMediaQuery } from '@material-ui/core';
import { Alert, Loading } from 'Components';
import { browserHistory } from 'Helper/history';
import { PrivateRouter } from 'Navigation/Router';
import { webauthnService } from 'Services/webauthn';
import { webauthnUtil } from 'Helper/webauthn';
import { bindActionCreators } from 'redux';
import { ActionSend } from 'Redux/actions';
import { connect } from 'react-redux';

const WebAuthnRegister = ({ t, ...props }) => {
  const { fetchWebAuthnCredentials } = props;
  const [deviceName, setDeviceName] = useState('');
  const [deviceNameError, setDeviceNameError] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  const handleClickSubmit = () => {
    if (deviceNameError) return;
    webauthnService
      .getRegistrationOptions()
      .then((creationOptions) => {
        webauthnUtil
          .createCredential(creationOptions)
          .then((credential) => {
            const utf8Decoder = new TextDecoder('utf-8');
            const decodedClientData = utf8Decoder.decode(credential.response.clientDataJSON);
            const clientDataObj = JSON.parse(decodedClientData);

            const publickeyCredential = webauthnUtil.buildRegistrationRequest(credential);
            const request = { deviceName: deviceName.trim(), publickeyCredential, registrationChallenge: clientDataObj.challenge };
            setIsLoading(true);
            webauthnService
              .registerWebAuthnCredential(request)
              .then((response) => {
                setIsLoading(false);
                fetchWebAuthnCredentials();
                const { webauthnCredentialId, challenge: nextChallenge } = response;
                browserHistory.push({
                  pathname: PrivateRouter.WebAuthnActivation.url,
                  search: `challenge=${nextChallenge}&webauthnCredentialId=${webauthnCredentialId}`,
                  state: { deviceName: deviceName.trim() },
                });
              })
              .catch(() => {
                Alert.error(t('Registration failed'));
              });
          })
          .catch((exception) => {
            // if this device is already registered
            if (
              exception.code === DOMException.INVALID_STATE_ERR &&
              exception.name === 'InvalidStateError' &&
              exception.message ===
                'The user attempted to register an authenticator that contains one of the credentials already registered with the relying party.'
            ) {
              Alert.error(t('This device is already registered'));
            } else Alert.error(t('Registration failed'));

            browserHistory.replace(PrivateRouter.Profile.url);
          });
      })
      .catch(() => {
        Alert.error(t('Registration failed'));
        browserHistory.replace(PrivateRouter.Profile.url);
      });
  };

  const handlePressKey = (event) => {
    if (event.key === 'Enter') {
      handleClickSubmit();
    }
  };

  const isBigScreen = useMediaQuery('(min-device-width: 1024px)');

  useEffect(() => {
    if (!window.PublicKeyCredential) {
      Alert.error(t('Your browser does not support, try with another browser'));
      browserHistory.replace(PrivateRouter.Profile.url);
    }
  }, [])

  return (
    <Fragment>
      <div className='flex-1 justify-content-center'>
        <div className='border-1px-solid-gray border-radius-4'>
          <div className={isBigScreen ? 'width-20vw mx-36 my-36' : 'mx-10vw my-10vh'}>
            <div className='mb-24 text-align-center'>
              <Typography variant='h5' className='mb-12'>
                {t('Register new device')}
              </Typography>
            </div>
            <div className='flex-center my-12'>
              <Divider className='flex-1' />
              <span className='mx-12 color-gray text-bolder'>{t('Enter device information')}</span>
              <Divider className='flex-1' />
            </div>
            <FormControl fullWidth className='mb-12'>
              <TextField
                label={t('Device name')}
                margin='dense'
                variant='outlined'
                value={deviceName}
                error={deviceNameError}
                onChange={(event) => {
                  const { value } = event.target;
                  setDeviceName(value);
                  setDeviceNameError(!value || value.length > 255);
                }}
                onKeyPress={(e) => handlePressKey(e)}
              />
            </FormControl>
            <div className='mb-24' />
            <FormControl fullWidth className='mb-24'>
              <Button color='primary' variant='contained' onClick={handleClickSubmit}>
                <Loading visible={isLoading} /> {t('Register new device')}
              </Button>
            </FormControl>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchWebAuthnCredentials: ActionSend.fetchWebAuthnCredentials,
    },
    dispatch,
  );

export default compose(withTranslation(), connect(null, mapDispatchToProps))(WebAuthnRegister);
