import React, { useState, useEffect, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect, Switch, Route } from 'react-router-dom';
import { Paper } from '@material-ui/core';
import { Icon } from 'antd';
import { ThemeCustomer, Logo, Error, LanguageSwitcher } from 'Components';
import { ActionSend } from 'Redux/actions';
import { authService } from 'Services/auth';
import { browserHistory, redirect } from 'Helper/history';
import { validator } from 'Helper/validate';
import { converter } from 'Helper/converter';
import { PublicRouter, PrivateRouter } from 'Navigation/Router';
import { supportLanguage } from 'i18n';
import vars from 'vars';

export const CLIENT_PERMISSIONS = {
  SOCIAL_FACEBOOK_LOGIN: 'social:facebook:login',
  SOCIAL_FACEBOOK_REGISTER: 'social:facebook:register',
  SOCIAL_GOOGLE_LOGIN: 'social:google:login',
  SOCIAL_GOOGLE_REGISTER: 'social:google:register',
  SOCIAL_APPLE_LOGIN: 'social:apple:login',
  SOCIAL_APPLE_REGISTER: 'social:apple:register',
  PASSWORDLESS_LOGIN: 'passwordless:login',
  PASSWORDLESS_REGISTER: 'passwordless:register',
  USERS_EMAIL_LOGIN: 'users:email:login',
  USERS_PHONE_NUMBER_LOGIN: 'users:phoneNumber:login',
  USERS_EMAIL_REGISTER: 'users:email:register',
  USERS_PHONE_NUMBER_REGISTER: 'users:phoneNumber:register',
  IBANKING_LOGIN: 'ibanking:login',
  PASSWORD_RESET: 'password:reset',
  WEBAUTHN_LOGIN: 'webauthn:login',
  WEBAUTHN_REGISTER: 'webauthn:register',
};

const PublicLayout = ({ i18n, t, ...props }) => {
  const { updateClient, updateOIDCContext } = props;

  const [challenge, setChallenge] = useState('');
  const [authnChallenge, setAuthnChallenge] = useState('');
  const [client, setClient] = useState({});
  const [oidcContext, setOIDCContext] = useState({});
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isFetched, setIsFetched] = useState(false);

  useEffect(() => {
    const publicRoutes = Object.values(PublicRouter).map((item) => item.url);
    const { search, pathname } = browserHistory.location;
    if (!publicRoutes.includes(pathname)) {
      browserHistory.replace(PrivateRouter.UserDashboard.url);
      return;
    }

    const parsed = converter.parseQueryString(search);
    const { challenge = '', locale = '', authnChallenge = '' } = parsed;

    setIsLoading(true);
    authService
      .getAuthentication({ challenge, pathname })
      .then((response) => {
        if (response) {
          const { skip, redirectTo, client, oidcContext } = response;
          const autoConfirmLogout = response.autoConfirm
          if (client) {
            if (Array.isArray(client.permissions) && client.permissions.length > 0) {
              // CLIENT ALREADY CONFIG PERMISSIONS => DO NOTHING
            } else {
              // CLIENT HAVE ALL PERMISSIONS BY DEFAULT => GRANT ALL PERMISSIONS
              client.permissions = Object.values(CLIENT_PERMISSIONS);
            }

            if (!client.ggApp) client.ggApp = { clientId: vars.REACT_APP_GOOGLE_CLIENT_ID };
            if (!client.fbApp) client.fbApp = { clientId: vars.REACT_APP_FACEBOOK_CLIENT_ID };

            setClient(client);
            updateClient(client);
            setOIDCContext(oidcContext)
            updateOIDCContext(oidcContext)

            const language = locale || localStorage.getItem('language') || client.i18n;
            supportLanguage(language);
            const clientTheme = client.theme || {};
            if (clientTheme.title) document.title = clientTheme.title;
            if (clientTheme.iconUri) document.querySelector('link[rel*="icon"]').href = clientTheme.iconUri;
          }
          // Handle silent login logic
          if (skip && pathname.startsWith(PublicRouter.Login.url)) {
            redirect(redirectTo);
          }
          // Handle silent logout logic
          if (autoConfirmLogout && pathname.startsWith(PublicRouter.Logout.url)) {
            redirect(redirectTo);
          }
          setIsFetched(true);
          setChallenge(challenge);
          setAuthnChallenge(authnChallenge);
        } else {
          setIsFetched(true);
          setIsError(1);
        }
      })
      .catch(() => {
        setIsFetched(true);
        setIsError(1);
      })
      .then(() => {
        setIsLoading(false);
      });
  }, [updateClient, i18n]);

  const { primaryColor, backgroundColor, backgroundImageUri } = useMemo(() => {
    const { primaryColor, backgroundColor, backgroundImageUri } = client.theme || {};
    return {
      primaryColor: validator.isValidColor(primaryColor) ? primaryColor : undefined,
      backgroundColor: validator.isValidColor(backgroundColor) ? backgroundColor : undefined,
      backgroundImageUri: backgroundImageUri || '/assets/background.svg',
    };
  }, [client]);

  return isFetched ? (
    <div>
      <ThemeCustomer primaryColor={primaryColor}>
        <div className='app'>
          <div
            className='background'
            style={{ backgroundColor: backgroundColor, backgroundImage: `url(${backgroundImageUri})` }}>
            <div className='form-container'>
              <div className='form-language-switcher'>
                <LanguageSwitcher />
              </div>
              <div className='form-content'>
                <Paper className='form-layout'>
                  <Logo client={client} />
                  {isLoading ? (
                    <Icon type='loading' />
                  ) : isError ? (
                    <Error />
                  ) : (
                    <Switch>
                      {Object.values(PublicRouter).map(({ screen: Component, ...item }) => (
                        <Route
                          exact
                          key={item.url}
                          path={item.url}
                          render={() => <Component authnChallenge={authnChallenge} challenge={challenge} client={client} oidcContext={oidcContext} />}
                        />
                      ))}
                      <Redirect from='/' to={{ pathname: PublicRouter.Error.url, state: 404 }} />
                    </Switch>
                  )}
                </Paper>
              </div>
            </div>
          </div>
        </div>
      </ThemeCustomer>
      <div
        style={{
          position: 'fixed',
          right: '10px',
          bottom: '5px',
          fontSize: 10,
          color: 'rgba(0,0,0,.85) !important',
          cursor: 'pointer',
        }}
        onClick={() => window.open('/privacy')}>
        {t('Privacy Policy')}
      </div>
    </div>
  ) : (
    <div className='app'>
      <div className='background' />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateClient: ActionSend.updateClient,
      updateOIDCContext: ActionSend.updateOIDCContext
    },
    dispatch,
  );

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(PublicLayout);
