import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Dialog, FormControl, Chip, Avatar, Divider, TextField, Button, Typography } from '@material-ui/core';
import { Logo, ColorLink, Loading } from 'Components';
import { ArrowBackIcon } from 'Components/Icons';
import { validator } from 'Helper/validate';
import { browserHistory } from 'Helper/history';
import { ActionSend } from 'Redux/actions';
import { PrivateRouter } from 'Navigation/Router';
import { mfaService } from 'Services/mfa';

let password = '';
export const storePassword = (value) => {
  if (value) password = value;
  return password;
};

const DialogLogin = ({ t, profile, ...props }) => {
  const { fetchMe } = props;

  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const isValidate = () => {
    let isError = false;
    if (validator.isEmpty(password)) {
      setPasswordError(true);
      isError = true;
    }
    return !isError;
  };

  const handleClickSubmit = () => {
    if (isValidate()) {
      const { state } = browserHistory.location;

      setIsLoading(true);
      mfaService
        .config({ password, ...state })
        .then(({ enabledMfa }) => {
          fetchMe();
          storePassword(password);

          if (enabledMfa) {
            browserHistory.push(PrivateRouter.TwoStepMain.url);
          } else {
            browserHistory.push(PrivateRouter.Security.url);
          }
        })
        .catch(() => {})
        .then(() => setIsLoading(false));
    }
  };

  const handleClickCancel = () => {
    browserHistory.goBack();
  };

  const handleClickForgot = (isForgot) => {
    setIsForgotPassword(isForgot);
  };

  const handlePressKey = (event) => {
    if (event.key === 'Enter') {
      handleClickSubmit();
    }
  };

  return (
    <Dialog disableBackdropClick disableEscapeKeyDown open className='DialogLogin'>
      <div className='Dialog-small-container' style={{ maxWidth: 360 }}>
        <div className='text-align-center mx-24'>
          <Logo />
          <Typography variant='h6' className='mb-36'>
            {t('Teko Identity Provider')}
          </Typography>
          <Chip size='medium' avatar={<Avatar src={profile.avatarUrl} />} label={profile.name} variant='outlined' />
          <div className='mb-12' />

          <div className='flex-center my-12'>
            <Divider className='flex-1' />
            {!isForgotPassword ? (
              <span className='mx-12 color-gray text-bolder'>{t("To continue, verify it's you")}</span>
            ) : (
              <span className='mx-12 color-error text-bolder'>{t('Sorry, something went wrong there')}</span>
            )}
            <Divider className='flex-1' />
          </div>

          <FormControl fullWidth className='mt-12 mb-36' style={isForgotPassword ? { visibility: 'hidden' } : {}}>
            <TextField
              autoFocus
              label={t('Enter your password')}
              variant='outlined'
              type='password'
              value={password}
              error={passwordError}
              onChange={(event) => {
                const { value } = event.target;
                setPassword(value);
                setPasswordError(validator.isEmpty(value));
              }}
              onKeyPress={(e) => handlePressKey(e)}
            />
          </FormControl>
          <div className='justify-content-between align-items-end mb-24'>
            <Typography style={isForgotPassword ? { visibility: 'hidden' } : {}}>
              <ColorLink onClick={() => handleClickForgot(true)}>{t('Forgot/Reset password')}</ColorLink>
            </Typography>

            {!isForgotPassword ? (
              <Button variant='contained' color='primary' onClick={() => handleClickSubmit()} style={{ minWidth: 120 }}>
                <Loading visible={isLoading} /> {t('Next')}
              </Button>
            ) : (
              <Button
                variant='contained'
                color='primary'
                onClick={() => handleClickForgot(false)}
                style={{ minWidth: 120 }}>
                {t('Back')}
              </Button>
            )}
          </div>
        </div>
        <div style={{ position: 'absolute', top: 18, cursor: 'pointer' }} title={t('Back')}>
          <ColorLink onClick={() => handleClickCancel()}>
            <ArrowBackIcon />
          </ColorLink>
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  profile: state.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchMe: ActionSend.fetchMe,
    },
    dispatch,
  );

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(DialogLogin);
