import React from 'react';
import { withTranslation } from 'react-i18next';
import { InputAdornment, Tooltip, withStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

const PasswordPolicyTooltip = ({ t, ...props }) => {
  const { passwordPolicy } = props;
  if (!passwordPolicy) return false;

  const { minLength, uppercase, number, special } = passwordPolicy;
  if (!minLength) return false;

  return (
    <HtmlTooltip
      title={
        <div>
          <div>{t('Password requirements')}:</div>
          {minLength ? <div>- {t('A minimum of {{minLength}} characters in length', { minLength })}.</div> : false}
          {uppercase ? <div>- {t('A minimum of {{uppercase}} uppercase characters', { uppercase })}.</div> : false}
          {number ? <div>- {t('A minimum of {{number}} number characters', { number })}.</div> : false}
          {special ? <div>- {t('A minimum of {{special}} special characters', { special })}.</div> : false}
        </div>
      }
      placement={'top'}>
      <InputAdornment position='end' className='color-gray cursor-default'>
        <InfoIcon />
      </InputAdornment>
    </HtmlTooltip>
  );
};

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.65)',
    maxWidth: 420,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

export default withTranslation()(PasswordPolicyTooltip);
