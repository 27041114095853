import React from 'react';
import { CircularProgress } from '@material-ui/core';

const Loading = ({ visible, Icon }) =>
  visible ? (
    <CircularProgress size={18} style={{ marginRight: 12, marginLeft: 2 }} color='inherit' />
  ) : Icon ? (
    <Icon style={{ marginRight: 8 }} />
  ) : (
    ''
  );

export default Loading;
