import React, { Fragment, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Typography, IconButton, FormControlLabel } from '@material-ui/core';
import { Button, Collapse, Upload, Modal, Popconfirm } from 'antd';
import { UserAvatar, AvatarEditor, CollapseItem, CollapseAuth, Alert } from 'Components';
import { CameraAltOutlinedIcon } from 'Components/Icons';
import { userService } from 'Services/user';
import moment from 'moment';
import QRCode from 'react-qr-code';
import { PrivateRouter } from 'Navigation/Router';
import { Link } from 'react-router-dom';
import { challengeService } from 'Services/challenge';
import { CHALLENGE_TYPE } from 'Constant/constant';
import vars from 'vars';
import { browserHistory } from 'Helper/history';
import { bindActionCreators } from 'redux';
import { ActionSend } from 'Redux/actions';
import { CheckCircle, RemoveCircle } from '@material-ui/icons';

const Profile = ({ t, ...props }) => {
  const { profile, webauthnCredentials, fetchWebAuthnCredentials } = props;
  const [activeKeys, setActiveKeys] = useState([]);
  const resetActiveKeys = () => setActiveKeys([]);

  const [activeAuthKeys, setActiveAuthKeys] = useState([]);
  const resetActiveAuthKeys = () => setActiveAuthKeys([]);

  const [image, setImage] = useState();
  const [visibleModalAvatar, setVisibleModalAvatar] = useState(false);

  const [visibleConfirmDeleteAccount, setVisibleConfirmDeleteAccount] = useState(false);

  const [tenant, setTenant] = useState({});

  const [visibleModalRegisterDevice, setVisibleModalRegisterDevice] = useState(false);

  const deleteAccount = () => {
    userService.deleteAccount().then(() => {
      setVisibleConfirmDeleteAccount(false);
      window.TekoID.user.unloadUser();
      window.location.reload();
    });
  };

  const handleClickActivateDevice = (credential) => {
    const getChallengeRequest = {
      challengeType: CHALLENGE_TYPE.ACTIVATION_WEBAUTHN,
      clientId: vars.REACT_APP_CLIENT_ID,
    };
    challengeService.getChallengeAuth(getChallengeRequest).then((res) => {
      const { challenge } = res;
      browserHistory.push({
        pathname: PrivateRouter.WebAuthnActivation.url,
        search: `challenge=${challenge}&webauthnCredentialId=${credential.id}`,
        state: { deviceName: credential.deviceName },
      });
    });
  };

  const handleClickDeleteDevice = (credential) => {
    const getChallengeRequest = {
      challengeType: CHALLENGE_TYPE.DELETION_WEBAUTHN,
      clientId: vars.REACT_APP_CLIENT_ID,
    };
    challengeService.getChallengeAuth(getChallengeRequest).then((res) => {
      const { challenge } = res;
      browserHistory.push({
        pathname: PrivateRouter.WebAuthnDeletion.url,
        search: `challenge=${challenge}&webauthnCredentialId=${credential.id}`,
        state: { deviceName: credential.deviceName },
      });
    });
  };

  useEffect(() => {
    userService
      .getTenantInfo()
      .then((res) => {
        setTenant(res);
        return res;
      })
      .then((tenantConfig) => {
        if (tenantConfig && tenantConfig.webauthnAllowed) {
          fetchWebAuthnCredentials();
        }
      })
      .catch(() => {});
  }, []);

  return (
    <Fragment>
      <div className='flex-1 mx-12'>
        <div className='text-align-center'>
          <Modal
            title={t('Update avatar')}
            destroyOnClose
            onCancel={() => setVisibleModalAvatar(false)}
            visible={visibleModalAvatar}
          >
            <AvatarEditor file={image} onCancel={() => setVisibleModalAvatar(false)} />
          </Modal>

          <Modal
            title={t('Are you sure you want to do this?')}
            onCancel={() => setVisibleConfirmDeleteAccount(false)}
            visible={visibleConfirmDeleteAccount}
          >
            <div className='flex-1 mx-12'>
              <Typography gutterBottom>{t('We will remove your account from our system')}.</Typography>
              <Typography gutterBottom>
                {t('Your username and your social accounts will be available to register afterward')}.
              </Typography>
              <div className='mb-12' />
              <Typography type='danger' color={'error'} gutterBottom style={{ fontWeight: 600 }}>
                {t('This action cannot be reverted')}.
              </Typography>
              <div className='mb-24' />

              <div className='justify-content-center'>
                <Button key='submit' type='danger' size='large' onClick={deleteAccount}>
                  {t('I understand and agree to remove this account')}
                </Button>
              </div>
            </div>
          </Modal>

          <Modal
            title={t('Scan QR code or access the following link')}
            onCancel={() => setVisibleModalRegisterDevice(false)}
            visible={visibleModalRegisterDevice}
          >
            <div className='text-align-center'>
              <QRCode size={150} value={window.location.origin + PrivateRouter.WebAuthnRegister.url} />
              <div className='mb-12' />
              <Link to={PrivateRouter.WebAuthnRegister.url}>
                {window.location.origin + PrivateRouter.WebAuthnRegister.url}
              </Link>
            </div>
          </Modal>

          <div className='user-avatar mb-12'>
            <UserAvatar size={128} />
            <Upload
              showUploadList={false}
              accept='image/*'
              customRequest={({ file }) => {
                if (file.type.startsWith('image/')) {
                  setImage(file);
                  setVisibleModalAvatar(true);
                } else {
                  Alert.error(t('The MIME type of the selected file is not allowed'));
                }
              }}
            >
              <div className='camera-frame'>
                <IconButton>
                  <CameraAltOutlinedIcon fontSize='small' />
                </IconButton>
              </div>
            </Upload>
          </div>
          <Typography variant='h5' className='mb-12'>
            {profile.name}
          </Typography>
          <Typography variant='body1' color='textSecondary' className='mb-12'>
            {t('Personal information')}
          </Typography>
        </div>
        <div className='justify-content-center'>
          <div style={{ flex: 1, maxWidth: 720 }}>
            <Collapse
              accordion
              destroyInactivePanel
              expandIconPosition='right'
              activeKey={activeKeys}
              onChange={(keys) => setActiveKeys(keys)}
            >
              <Collapse.Panel
                className='ant-collapse-panel-header'
                header={<Typography variant='h6'>{t('Profile')}</Typography>}
              />
              <Collapse.Panel key='name' header={<CollapseHeader title={t('Name')} content={profile.name} />}>
                <CollapseItem init={profile.name} field='name' onUpdate={resetActiveKeys} />
              </Collapse.Panel>
              <Collapse.Panel
                key='birthday'
                header={
                  <CollapseHeader
                    title={t('Birthday')}
                    content={
                      profile.birthday && moment(profile.birthday).isValid()
                        ? moment(profile.birthday).format('DD/MM/YYYY')
                        : null
                    }
                  />
                }
              >
                <CollapseItem
                  init={profile.birthday ? moment(profile.birthday) : null}
                  field='birthday'
                  onUpdate={resetActiveKeys}
                />
              </Collapse.Panel>
              <Collapse.Panel key='gender' header={<CollapseHeader title={t('Gender')} content={t(profile.gender)} />}>
                <CollapseItem init={profile.gender} field='gender' onUpdate={resetActiveKeys} />
              </Collapse.Panel>
            </Collapse>
            <div className='mb-48' />
            <Collapse
              accordion
              destroyInactivePanel
              expandIconPosition='right'
              activeKey={activeAuthKeys}
              onChange={(keys) => setActiveAuthKeys(keys)}
            >
              <Collapse.Panel
                className='ant-collapse-panel-header'
                header={<Typography variant='h6'>{t('Contact information')}</Typography>}
              />
              <Collapse.Panel key='email' header={<CollapseHeader title={t('Email')} content={profile.email} />}>
                <CollapseAuth init={''} field='email' onUpdate={resetActiveAuthKeys} />
              </Collapse.Panel>
              <Collapse.Panel
                key='phoneNumber'
                header={<CollapseHeader title={t('Phone number')} content={profile.phoneNumber} />}
              >
                <CollapseAuth init={''} field='phoneNumber' onUpdate={resetActiveAuthKeys} />
              </Collapse.Panel>
            </Collapse>
            <div className='mb-48' />
            {tenant && tenant.webauthnAllowed && (
              <div>
                <Collapse accordion destroyInactivePanel expandIconPosition='right'>
                  <Collapse.Panel
                    key='myDevices'
                    className='ant-collapse-panel-header'
                    header={<Typography variant='h6'>{t('My devices')}</Typography>}
                  />
                  {webauthnCredentials.map((credential) => (
                    <Collapse.Panel
                      key={`device-${credential.deviceName}`}
                      header={
                        <div className='form-item-header'>
                          <div className='form-item-label-no-upper'>{credential.deviceName}</div>
                          <div>
                            <FormControlLabel
                              className='ml-0'
                              control={
                                credential.active ? (
                                  <CheckCircle color='primary' className='mr-12' />
                                ) : (
                                  <RemoveCircle className='mr-12' />
                                )
                              }
                              label={credential.active ? t('Activated') : t('Not activated')}
                            />
                          </div>
                        </div>
                      }
                    >
                      <Collapse.Panel
                        key={`device-${credential.deviceName}-actions`}
                        disabled
                        className='ant-collapse-panel-no-background'
                        showArrow={false}
                        header={
                          <div className='form-item-header'>
                            <div className='form-item-label' />
                            <div>
                              <Button
                                type='primary'
                                disabled={credential.active}
                                onClick={() => handleClickActivateDevice(credential)}
                              >
                                {t('Verify')}
                              </Button>
                              <Popconfirm
                                placement='topLeft'
                                title={t('Are you sure to delete this device?')}
                                onConfirm={() => handleClickDeleteDevice(credential)}
                                okText={t('Yes')}
                                cancelText={t('Cancel')}
                              >
                                <Button className='ml-12' type='danger'>
                                  {t('Delete')}
                                </Button>
                              </Popconfirm>
                            </div>
                          </div>
                        }
                      />
                    </Collapse.Panel>
                  ))}
                  <Collapse.Panel
                    disabled
                    className='ant-collapse-panel-no-background'
                    showArrow={false}
                    header={
                      <Button type='primary' onClick={() => setVisibleModalRegisterDevice(true)}>
                        {t('Register new device')}
                      </Button>
                    }
                  />
                </Collapse>
                <div className='mb-48' />
              </div>
            )}
            <Collapse accordion destroyInactivePanel expandIconPosition='right'>
              <Collapse.Panel
                className='ant-collapse-panel-header'
                header={<Typography variant='h6'>{t('Delete account')}</Typography>}
              />
              <Collapse.Panel
                disabled
                className='ant-collapse-panel-no-background'
                showArrow={false}
                header={
                  <Button type='danger' onClick={() => setVisibleConfirmDeleteAccount(true)}>
                    {t('Delete your account')}
                  </Button>
                }
              />
            </Collapse>
            <div className='mb-48' />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const CollapseHeader = ({ title, content }) => (
  <div className='form-item-header'>
    <div className='form-item-label'>{title}</div>
    <div>
      <Typography variant='body1'>{content}</Typography>
    </div>
  </div>
);

const mapStateToProps = (state) => {
  return {
    profile: state.user,
    webauthnCredentials: state.webauthnCredential.list,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchWebAuthnCredentials: ActionSend.fetchWebAuthnCredentials,
    },
    dispatch,
  );

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Profile);
