import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { MenuItem, Divider } from '@material-ui/core';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import PhotoFilterIcon from '@material-ui/icons/PhotoFilter';
import SecurityIcon from '@material-ui/icons/Security';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ColorLensOutlinedIcon from '@material-ui/icons/ColorLensOutlined';
import { authService } from 'Services/auth';
import { browserHistory } from 'Helper/history';
import { PrivateRouter } from 'Navigation/Router';
import { Icon } from 'antd';

const Menu = ({ t, ...props }) => {
  const { pathname } = browserHistory.location;

  const handleClickMenu = () => {
    const { onClickMenu } = props;
    if (onClickMenu) {
      onClickMenu();
    }
  };

  return (
    <div className='app-menu' onClick={() => handleClickMenu()}>
      <Link to={PrivateRouter.UserDashboard.url}>
        <MenuItem className={pathname === PrivateRouter.UserDashboard.url ? 'active' : ''}>
          <DashboardOutlinedIcon /> {t('Dashboard')}
        </MenuItem>
      </Link>
      <Link to={PrivateRouter.Profile.url}>
        <MenuItem className={pathname === PrivateRouter.Profile.url ? 'active' : ''}>
          <AccountCircleOutlinedIcon /> {t('Personal information')}
        </MenuItem>
      </Link>
      <MenuItem className='inactive'>
        <PhotoFilterIcon /> {t('Data and personalization')}
      </MenuItem>
      <MenuItem className='inactive'>
        <SecurityIcon /> {t('Security')}
      </MenuItem>
      <MenuItem className='inactive'>
        <PeopleAltOutlinedIcon /> {t('People and sharing')}
      </MenuItem>
      <Divider className='my-4' />
      <MenuItem className='inactive'>
        <HelpOutlineIcon /> {t('Help')}
      </MenuItem>
      <MenuItem className='inactive'>
        <ColorLensOutlinedIcon /> {t('Send feedback')}
      </MenuItem>
      <MenuItem onClick={() => authService.logout()}>
        <Icon type='logout' style={{ fontSize: 20, marginLeft: 2, marginRight: 14 }} /> {t('Log out')}
      </MenuItem>
    </div>
  );
};

export default withTranslation()(Menu);
