import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { Border } from 'Components';
import { Typography, Button } from '@material-ui/core';
import { PrivateRouter } from 'Navigation/Router';
import { ArrowBackIcon } from 'Components/Icons';
import { browserHistory } from 'Helper/history';

export const staticGoogleImageUrl =
  'https://www.gstatic.com/identity/boq/accountsettingssecuritycommon/images/sprites/2sv_x2-376cf0cbd62be6b103c146c2464cdff9.png';

const Greeting = ({ t, profile }) => {
  const handleClickStart = () => {
    browserHistory.replace({
      pathname: PrivateRouter.Authenticator.url,
      state: { enabled: true },
    });
  };

  const handleClickBack = () => {
    browserHistory.replace(PrivateRouter.Security.url);
  };

  return (
    <Fragment>
      <div className='flex-1 mx-12'>
        <div className='justify-content-center'>
          <div style={{ flex: 1, maxWidth: 720 }}>
            <div className='align-items-center mb-12'>
              <ArrowBackIcon className='cursor-pointer mr-12' onClick={() => handleClickBack()} />
              <Typography variant='h6'>{t('2-Step Verification')}</Typography>
            </div>
            <Border.Group>
              <Border style={{ backgroundColor: '#4285f4', borderColor: '#4285f4' }}>
                <div
                  style={{
                    background: `no-repeat url(${staticGoogleImageUrl}) 0 -2818px`,
                    backgroundSize: '304px 3143px',
                    width: '253px',
                    height: '163px',
                    margin: '-6px auto -18px auto',
                  }}
                />
              </Border>
              <Border>
                <Typography variant='subtitle1'>{t('Protect your account with 2-Step Verification')}</Typography>
                <Typography variant='body2' color='textSecondary'>
                  {t('Each time you sign in to your account, you will need your password and a verification code')}.
                </Typography>
              </Border>
              <Border className='flex-row'>
                <div
                  style={{
                    background: `no-repeat url(${staticGoogleImageUrl}) 0 -606px`,
                    backgroundSize: '304px 3143px',
                    ...styleImage,
                  }}
                />
                <div style={{ maxWidth: 360 }}>
                  <Typography variant='subtitle1'>{t('Add an extra layer of security')}</Typography>
                  <Typography variant='body2' color='textSecondary'>
                    {t('Enter your password and a unique verification code that is sent to your phone')}.
                  </Typography>
                </div>
              </Border>
              <Border className='flex-row'>
                <div
                  style={{
                    background: `no-repeat url(${staticGoogleImageUrl}) 0 -1122px`,
                    backgroundSize: '304px 3143px',
                    ...styleImage,
                  }}
                />
                <div style={{ maxWidth: 360 }}>
                  <Typography variant='subtitle1'>{t('Keep the bad guys out')}</Typography>
                  <Typography variant='body2' color='textSecondary'>
                    {t('Even if someone else gets your password, it would not be enough to sign in to your account')}.
                  </Typography>
                </div>
              </Border>
              <Border className='justify-content-end'>
                <Button variant='contained' color='primary' onClick={() => handleClickStart()}>
                  {t('Get Started')}
                </Button>
              </Border>
            </Border.Group>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const styleImage = {
  minWidth: '105px',
  height: '79px',
  marginRight: '48px',
};

export default withTranslation()(Greeting);
