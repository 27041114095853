import React, { Fragment, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { Button, Divider, FormControl, InputAdornment, TextField, Typography, useMediaQuery } from '@material-ui/core';
import { Alert, ColorLink, Loading } from 'Components';
import { DevicesIcon } from 'Components/Icons';
import { browserHistory } from 'Helper/history';
import { validator } from 'Helper/validate';
import { PrivateRouter, PublicRouter } from 'Navigation/Router';
import { challengeService } from 'Services/challenge';
import { converter } from 'Helper/converter';
import { bindActionCreators } from 'redux';
import { ActionSend } from 'Redux/actions';
import { connect } from 'react-redux';

const WebAuthnActivation = ({ t, fetchWebAuthnCredentials }) => {
  const { state = {} } = browserHistory.location;
  const { search } = browserHistory.location;
  const parsed = converter.parseQueryString(search);
  const { challenge, webauthnCredentialId } = parsed;

  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCode, setIsLoadingCode] = useState(false);
  const [counter, setCounter] = useState(0);

  const isValidate = () => {
    let isError = false;
    if (!validator.isValidCode(code)) {
      setCodeError(true);
      isError = true;
    }
    return !isError;
  };

  const handleClickSubmit = () => {
    if (isValidate()) {
      const challengeResponse = { webauthnCredentialId, code };
      const request = { challenge, challengeResponse };
      setIsLoading(true);
      challengeService
        .resolveChallengeAuth(request)
        .then((response) => {
          Alert.success(t('Your device is now active'));
          fetchWebAuthnCredentials();
          browserHistory.push({
            pathname: PrivateRouter.Profile.url,
          });
        })
        .catch(() => {})
        .then(() => setIsLoading(false));
    }
  };

  const handlePressKey = (event) => {
    if (event.key === 'Enter') {
      handleClickSubmit();
    }
  };

  const resendActivationCode = () => {
    if (counter > 0) return;

    const request = { challenge };
    setIsLoadingCode(true);
    challengeService
      .getChallengeAuth(request)
      .then((response) => {
        Alert.success(t('Please check your email or phone'));
        setCounter(60);
      })
      .catch(() => {})
      .then(() => setIsLoadingCode(false));
  };

  useEffect(() => {
    let minusCounter;
    if (counter > 0) {
      minusCounter = setTimeout(() => {
        setCounter((counter) => counter - 1);
      }, 1000);
    }
    return () => clearTimeout(minusCounter);
  }, [counter]);

  useEffect(() => {
    if (state.deviceName && challenge && webauthnCredentialId) {
      Alert.success(t('Please check your email or phone'));
    } else {
      browserHistory.replace({ pathname: PublicRouter.Error.url, state: 500 });
    }
  }, [state]);

  const isBigScreen = useMediaQuery('(min-device-width: 1024px)');

  return (
    <Fragment>
      <div className='flex-1 justify-content-center'>
        <div className='border-1px-solid-gray border-radius-4'>
          <div className={isBigScreen ? 'width-20vw mx-36 my-36' : 'mx-10vw my-10vh'}>
            <Typography variant='h5' className='mb-36 text-align-center'>
              {t('Device verification')}
            </Typography>
            <div className='flex-center my-12'>
              <Divider className='flex-1' />
              <span className='mx-12 color-gray text-bolder'>{t('Enter your activation code')}</span>
              <Divider className='flex-1' />
            </div>
            <FormControl fullWidth className='mb-12'>
              <TextField
                label={t('Device')}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position='end'>
                      <DevicesIcon />
                    </InputAdornment>
                  ),
                }}
                margin='dense'
                variant='outlined'
                value={state.deviceName}
              />
            </FormControl>
            <FormControl fullWidth className='mb-12'>
              <TextField
                autoFocus
                label={t('Activation code')}
                margin='dense'
                variant='outlined'
                value={code}
                error={codeError}
                onChange={(event) => {
                  const { value } = event.target;
                  setCode(value);
                  setCodeError(!validator.isValidCode(value));
                }}
                onKeyPress={(e) => handlePressKey(e)}
              />
            </FormControl>
            <FormControl fullWidth>
              <Typography component='div' variant='body2' className='justify-content-center'>
                <ColorLink className='align-items-center' disabled={counter > 0} onClick={resendActivationCode}>
                  <Loading visible={isLoadingCode} />
                  {counter > 0 && <span style={{ marginRight: 8 }}>({counter})</span>}
                  {t('Resend activation code')}
                </ColorLink>
              </Typography>
            </FormControl>
            <div className='mb-24' />
            <FormControl fullWidth className='mb-24'>
              <Button color='primary' variant='contained' onClick={handleClickSubmit}>
                <Loading visible={isLoading} /> {t('Verify device')}
              </Button>
            </FormControl>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchWebAuthnCredentials: ActionSend.fetchWebAuthnCredentials,
    },
    dispatch,
  );

export default compose(withTranslation(), connect(null, mapDispatchToProps))(WebAuthnActivation);
