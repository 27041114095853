import React, { Fragment, useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Border, ColorLink } from 'Components';
import { Typography, Button, Dialog } from '@material-ui/core';
import { ArrowBackIcon, EmailIcon, VibrationIcon } from 'Components/Icons';
import { browserHistory } from 'Helper/history';
import { ActionSend } from 'Redux/actions';
import { PrivateRouter } from 'Navigation/Router';
import DialogAuthen from './2StepAuthen';
import DialogSms from './2StepSms';
import DialogEmail from './2StepEmail';

import { staticGoogleImageUrl } from './Greeting';
import { storePassword } from 'ScreensPrivate/Authenticator';

const TwoStepMain = ({ t, profile, ...props }) => {
  const { fetchMe } = props;
  const { phoneNumber, email, authenticatorType: loginType } = profile;

  const [isOpenDialog, setIsOpenDialog] = useState(0);

  const handleOpenDialog = (dialog) => setIsOpenDialog(dialog);
  const handleCloseDialog = () => setIsOpenDialog(0);

  const handleClickOff = () => {
    browserHistory.push({
      pathname: PrivateRouter.Authenticator.url,
      state: { enabled: false },
    });
  };

  const handleSuccessDialog = () => {
    fetchMe();
  };

  const handleClickBack = () => {
    browserHistory.replace(PrivateRouter.Security.url);
  };

  useEffect(() => {
    if (!storePassword()) {
      browserHistory.replace(PrivateRouter.Security.url);
    }
  }, []);

  return (
    <Fragment>
      <div className='flex-1 mx-12'>
        <div className='justify-content-center'>
          <div style={{ flex: 1, maxWidth: 720 }}>
            <div className='align-items-center mb-12'>
              <ArrowBackIcon className='cursor-pointer mr-12' onClick={() => handleClickBack()} />
              <Typography variant='h6'>{t('2-Step Verification')}</Typography>
            </div>

            <Border.Group className='mb-24'>
              <Border className='justify-content-between align-items-center'>
                <Typography variant='body2' color='textSecondary'>
                  {t('You need to active one of these methods below to use 2-Step Verification')}.
                </Typography>
                <Button
                  variant='contained'
                  color='primary'
                  style={{ width: 90 }}
                  className='ml-12'
                  onClick={() => handleClickOff()}>
                  {t('OFF')}
                </Button>
              </Border>
            </Border.Group>

            <div className='mb-24'>
              <Typography variant='body1'>{t('Available methods')}</Typography>
              <Typography variant='body2' color='textSecondary'>
                {t("A second step after entering your password verifies it's you signing in")}.
              </Typography>
            </div>

            <Border.Group className='mb-12'>
              <Border className='flex-row' style={loginType === 'totp' ? styleBorder : {}}>
                <div
                  style={{
                    background: `no-repeat url(${staticGoogleImageUrl}) 0 0`,
                    backgroundSize: '304px 3143px',
                    ...styleIcon,
                  }}
                />
                <div>
                  <Typography variant='subtitle1'>{'Google Authenticator'}</Typography>
                  <Typography variant='body2' color='textSecondary'>
                    {t('Use Google Authenticator to get free verification codes, even when your phone is offline')}.
                  </Typography>
                  <div className='mb-12' />

                  <ColorLink onClick={() => handleOpenDialog(1)}>
                    <Typography variant='button'>{t('SET UP')}</Typography>
                  </ColorLink>
                </div>
              </Border>
              <Dialog disableBackdropClick open={isOpenDialog === 1}>
                <DialogAuthen onCancel={() => handleCloseDialog()} onSuccess={() => handleSuccessDialog()} />
              </Dialog>
            </Border.Group>

            <Border.Group className='mb-12'>
              <Border className='flex-row' style={loginType === 'sms' ? styleBorder : {}}>
                <VibrationIcon style={{ color: '#757575', ...styleIcon }} />
                <div>
                  <Typography variant='subtitle1'>{'SMS OTP'}</Typography>
                  <Typography variant='body2' color='textSecondary'>
                    {t('Get verification codes by text message')}.
                  </Typography>
                  <div className='mb-12' />

                  {Boolean(phoneNumber) ? (
                    <ColorLink onClick={() => handleOpenDialog(2)}>
                      <Typography variant='button'>{t('SET UP')}</Typography>
                    </ColorLink>
                  ) : (
                    <Typography variant='button' color='error'>
                      {t('Active your phone number first')}
                    </Typography>
                  )}
                </div>
              </Border>
              <Dialog disableBackdropClick open={isOpenDialog === 2}>
                <DialogSms onCancel={() => handleCloseDialog()} onSuccess={() => handleSuccessDialog()} />
              </Dialog>
            </Border.Group>

            <Border.Group>
              <Border className='flex-row' style={loginType === 'email' ? styleBorder : {}}>
                <EmailIcon style={{ color: '#757575', ...styleIcon }} />
                <div>
                  <Typography variant='subtitle1'>{'Email OTP'}</Typography>
                  <Typography variant='body2' color='textSecondary'>
                    {t('Get verification codes by email')}.
                  </Typography>
                  <div className='mb-12' />

                  {Boolean(email) ? (
                    <ColorLink onClick={() => handleOpenDialog(3)}>
                      <Typography variant='button'>{t('SET UP')}</Typography>
                    </ColorLink>
                  ) : (
                    <Typography variant='button' color='error'>
                      {t('Active your email first')}
                    </Typography>
                  )}
                </div>
              </Border>
              <Dialog disableBackdropClick open={isOpenDialog === 3}>
                <DialogEmail onCancel={() => handleCloseDialog()} onSuccess={() => handleSuccessDialog()} />
              </Dialog>
            </Border.Group>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const styleIcon = {
  minWidth: '36px',
  height: '36px',
  margin: '12px 36px 12px 0px',
};

const styleBorder = {
  borderLeft: '4px solid #f4bf42',
};

const mapStateToProps = (state) => ({
  profile: state.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchMe: ActionSend.fetchMe,
    },
    dispatch,
  );

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(TwoStepMain);
