import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const ThemeCustomer = ({ primaryColor = '#2196f3', ...props }) => {
  return (
    <ThemeProvider
      {...props}
      theme={createMuiTheme({
        palette: {
          primary: {
            main: primaryColor,
          },
        },
      })}
    />
  );
};

export default ThemeCustomer;
