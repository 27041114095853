import { notification } from 'antd';

const success = (message) => {
  notification.success({ message });
};

const error = (message) => {
  notification.error({ message });
};

export default {
  success,
  error,
};
