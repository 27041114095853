import { createStore, applyMiddleware, compose } from 'redux';
import ReduxThunk from 'redux-thunk';
import { reducers } from '../reducers';

const configureStore = (initialState = {}) => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const middlewares = [ReduxThunk];
  return createStore(reducers, initialState, composeEnhancers(applyMiddleware(...middlewares)));
};

export const store = configureStore();
