import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { withPermission } from 'Containers/ScreenPermission';
import { compose } from 'recompose';
import { CLIENT_PERMISSIONS as P } from 'Containers/PublicLayout';
import { Button, Divider, FormControl, TextField, Typography } from '@material-ui/core';
import { Alert, Loading } from 'Components';
import { browserHistory, redirect } from 'Helper/history';
import { validator } from 'Helper/validate';
import { challengeService } from 'Services/challenge';
import { CHALLENGE_TYPE, ERROR_CODE } from 'Constant/constant';
import { PublicRouter } from 'Navigation/Router';
import { Modal } from 'antd';
import QRCode from 'react-qr-code';
import { Link } from 'react-router-dom';
import { webauthnUtil } from 'Helper/webauthn';

const LoginWebAuthn = ({ t, challenge, client }) => {
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState(true);
  const [authnChallenge, setAuthnChallenge] = useState('');
  const [redirectUrlKey, setRedirectUrlKey] = useState('');
  const [getRedirectUrlChallenge, setGetRedirectUrlChallenge] = useState('');
  const [pullRedirectUrl, setPullRedirectUrl] = useState(false);
  const [counter, setCounter] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [visibleModal, setVisibleModal] = useState(false);

  const { buttonColor } = useMemo(() => {
    const { buttonColor } = client.theme || {};
    return {
      buttonColor: validator.isValidColor(buttonColor) ? buttonColor : undefined,
    };
  }, [client]);

  const handleClickSubmit = () => {
    if (!validator.isValidUsername(username) || isLoading) return;
    const request = {
      challengeType: CHALLENGE_TYPE.AUTHN_WEBAUTHN,
      clientId: client.clientId,
      oauthChallenge: challenge,
      username,
    };
    setIsLoading(true);
    challengeService
      .getChallenge(request)
      .then((response) => {
        const { challenge: authnChallenge, challengeRequest } = response;
        const { redirectUrlKey, getRedirectUrlChallenge, publickeyCredentialRequestOptions } = challengeRequest;

        webauthnUtil
          .getCredential(publickeyCredentialRequestOptions)
          .then((credential) => {
            const authenticationRequest = webauthnUtil.buildAuthenticationRequest(credential);
            const challengeResponse = {
              challenge: authnChallenge,
              challengeResponse: authenticationRequest,
            };
            challengeService
              .resolveChallenge(challengeResponse)
              .then(() => {
                Alert.success(t('Verify device succeeded'));
              })
              .catch(() => {
                setAuthnChallenge(authnChallenge);
                setVisibleModal(true);
                setCounter(300);
                setIsLoading(false);
              });
          })
          .catch(() => {
            setAuthnChallenge(authnChallenge);
            setVisibleModal(true);
            setCounter(300);
            setIsLoading(false);
          })
          .finally(() => {
            setRedirectUrlKey(redirectUrlKey);
            setGetRedirectUrlChallenge(getRedirectUrlChallenge);
            setPullRedirectUrl(true);
          });
      })
      .catch(() => setIsLoading(false));
  };

  const handlePressKey = (event) => {
    if (event.key === 'Enter') {
      handleClickSubmit();
    }
  };

  const toClockFormat = (counter) => {
    const minute = Math.floor(counter / 60);
    const second = counter % 60;
    const minuteStr = minute < 10 ? `0${minute}` : `${minute}`;
    const secondStr = second < 10 ? `0${second}` : `${second}`;
    return `${minuteStr}:${secondStr}`;
  };

  useEffect(() => {
    if (!window.PublicKeyCredential) {
      Alert.error(t('Your browser does not support, try with another browser'));
      browserHistory.push({
        pathname: PublicRouter.Login.url,
        search: `challenge=${challenge}`,
      });
    }
  }, []);

  useEffect(() => {
    if (!pullRedirectUrl) return;
    const request = {
      challenge: getRedirectUrlChallenge,
      challengeResponse: { redirect_url_key: redirectUrlKey },
    };
    const stopRetry = setTimeout(() => {
      clearInterval(retryGetRedirectUrl);
      Alert.error(t('Your request has expired'));
      setVisibleModal(false);
      setPullRedirectUrl(false);
      setCounter(0);
      setIsLoading(false);
    }, 5 * 60 * 1000);

    const retryGetRedirectUrl = setInterval(() => {
      challengeService
        .resolveChallenge(request)
        .then((res) => {
          const { code: errCode, redirectTo } = res;
          if (errCode !== ERROR_CODE.WEBAUTHN_WAIT_DEVICE_VERIFICATION && !!redirectTo) {
            clearInterval(retryGetRedirectUrl);
            clearTimeout(stopRetry);
            redirect(redirectTo);
          }
        })
        .catch(() => {
          clearInterval(retryGetRedirectUrl);
          clearTimeout(stopRetry);
          setVisibleModal(false);
          setPullRedirectUrl(false);
          setCounter(0);
          setIsLoading(false);
        });
    }, 1000);

    return () => {
      clearInterval(retryGetRedirectUrl);
      clearTimeout(stopRetry);
    };
  }, [pullRedirectUrl, redirectUrlKey]);

  useEffect(() => {
    let minusCounter;
    if (counter > 0) {
      minusCounter = setTimeout(() => {
        setCounter((counter) => counter - 1);
      }, 1000);
    }
    return () => clearTimeout(minusCounter);
  }, [counter]);

  return (
    <Fragment>
      <Modal
        title={t('Scan QR code or access the following link')}
        destroyOnClose
        onCancel={() => setVisibleModal(false)}
        visible={visibleModal && !!authnChallenge}
      >
        <div className='text-align-center'>
          {counter > 0 && (
            <Typography variant='h6' className='mb-36'>
              {`${t('QR code (link) will expire after')}: ${toClockFormat(counter)}`}
            </Typography>
          )}
          <div className='mb-12' />
          <QRCode
            size={150}
            value={`${window.location.origin}${PublicRouter.WebAuthnVerifyDevice.url}?challenge=${challenge}&authnChallenge=${authnChallenge}`}
          />
          <div className='mb-12' />
          <Link
            target='_blank'
            to={`${PublicRouter.WebAuthnVerifyDevice.url}?challenge=${challenge}&authnChallenge=${authnChallenge}`}
          >
            {`${window.location.origin}${PublicRouter.WebAuthnVerifyDevice.url}?challenge=${challenge}&authnChallenge=${authnChallenge}`}
          </Link>
        </div>
      </Modal>
      <Typography variant='h5' className='mb-36'>
        {t('Log in with Device')}
      </Typography>
      <div className='flex-center my-12'>
        <Divider className='flex-1' />
        <span className='mx-12 color-gray text-bolder'>{t('Enter your email or phone number')}</span>
        <Divider className='flex-1' />
      </div>
      <FormControl fullWidth className='mb-12'>
        <TextField
          label={t('Email or phone number')}
          margin='dense'
          variant='outlined'
          value={username}
          error={usernameError}
          onChange={(event) => {
            const { value } = event.target;
            setUsername(value);
            setUsernameError(!validator.isValidUsername(value));
          }}
          onKeyPress={(e) => handlePressKey(e)}
        />
      </FormControl>
      <div className='mb-24' />

      <FormControl fullWidth className='mb-24'>
        <Button
          disabled={isLoading}
          color='primary'
          style={{ color: buttonColor }}
          variant='contained'
          onClick={handleClickSubmit}
        >
          <Loading visible={isLoading} /> {t('Log in')}
        </Button>
      </FormControl>

      {counter > 0 && (
        <div className='mt-12'>
          <Typography variant='h6'>{`${t('Waiting for device verification')}: ${toClockFormat(counter)}`}</Typography>
          <Typography variant='h6'>{t('Please do not reload this page')}</Typography>
        </div>
      )}
    </Fragment>
  );
};

export default compose(withPermission([P.WEBAUTHN_LOGIN]), withTranslation())(LoginWebAuthn);
