import React, { Fragment, useMemo, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { withPermission } from 'Containers/ScreenPermission';
import { compose } from 'recompose';
import { CLIENT_PERMISSIONS as P } from 'Containers/PublicLayout';
import { Button, FormControl, Typography } from '@material-ui/core';
import { Alert, Loading } from 'Components';
import { challengeService } from 'Services/challenge';
import { webauthnUtil } from 'Helper/webauthn';
import { Button as AntdButton, Modal } from 'antd';
import { validator } from 'Helper/validate';
import { browserHistory } from 'Helper/history';
import { PrivateRouter } from 'Navigation/Router';

const WebAuthnVerifyDevice = ({ t, authnChallenge, client }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [visibleModal, setVisibleModal] = useState(true);
  const { clientName } = client;
  const [counter, setCounter] = useState(0);
  const [isVerified, setIsVerified] = useState(false);

  const { buttonColor } = useMemo(() => {
    const { buttonColor } = client.theme || {};
    return {
      buttonColor: validator.isValidColor(buttonColor) ? buttonColor : undefined,
    };
  }, [client]);

  const onCancelModal = () => {
    setIsLoading(false);
    setVisibleModal(false);
  };

  const handleClickVerify = () => {
    setIsLoading(true);
    setVisibleModal(true);
  };

  const handleClickSubmit = () => {
    setVisibleModal(false);
    if (!window.PublicKeyCredential) {
      Alert.error(t('Your browser does not support, try with another browser'));
      setIsLoading(false);
      return;
    }
    challengeService
      .getChallenge({ challenge: authnChallenge })
      .then((res) => {
        const { challengeRequest } = res;
        const { publickeyCredentialRequestOptions } = challengeRequest;
        webauthnUtil
          .getCredential(publickeyCredentialRequestOptions)
          .then((credential) => {
            const authenticationRequest = webauthnUtil.buildAuthenticationRequest(credential);
            const challengeResponse = {
              challenge: authnChallenge,
              challengeResponse: authenticationRequest,
            };
            challengeService
              .resolveChallenge(challengeResponse)
              .then(() => {
                Alert.success(t('Verify device succeeded'));
                setCounter(5);
                setIsVerified(true);
              })
              .catch(() => {
                Alert.error(t('Verify device failed, please close this tab and try again'));
              });
          })
          .catch(() => Alert.error(t('Verify device failed')));
      })
      .catch(() => {
        Alert.error(t('Please close this tab and try again'));
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    let minusCounter;
    if (counter > 0) {
      minusCounter = setTimeout(() => {
        setCounter((counter) => counter - 1);
      }, 1000);
    } else {
      if (isVerified) browserHistory.push({ pathname: PrivateRouter.UserDashboard.url });
    }
    return () => clearTimeout(minusCounter);
  }, [counter, isVerified]);

  return (
    <Fragment>
      <Modal title={t('You are logging on to ') + clientName} onCancel={onCancelModal} visible={visibleModal}>
        <div className='flex-1 mx-12'>
          <Typography gutterBottom>{t('Are you sure you want to do this?')}</Typography>
          <div className='mb-24' />
          <div className='justify-content-center'>
            <AntdButton key='submit' type='primary' size='large' onClick={handleClickSubmit}>
              {t('Continue')}
            </AntdButton>
          </div>
        </div>
      </Modal>
      <Typography variant='h5' className='mb-36'>
        {t('Verify device')}
      </Typography>
      <div className='mb-24' />

      <FormControl fullWidth>
        <Button
          disabled={isVerified || visibleModal}
          color='primary'
          style={{ color: buttonColor }}
          variant='contained'
          onClick={handleClickVerify}
        >
          <Loading visible={isLoading} /> {t('Verify')}
        </Button>
      </FormControl>

      {counter > 0 && (
        <div>
          <Typography variant='h6'>
            {`${t('Verification succeeded, you will be redirected after')}: ${counter}`}
          </Typography>
        </div>
      )}
    </Fragment>
  );
};

export default compose(withPermission([P.WEBAUTHN_LOGIN]), withTranslation())(WebAuthnVerifyDevice);
