import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Redirect, Switch, Route } from 'react-router-dom';
import { Paper } from '@material-ui/core';
import { ThemeCustomer, Logo } from 'Components';
import { AlertRouter } from 'Navigation/Router';
import { validator } from 'Helper/validate';

const PublicLayout = ({ client, ...props }) => {
  const { primaryColor, backgroundColor, backgroundImageUri } = useMemo(() => {
    const { primaryColor, backgroundColor, backgroundImageUri } = client.theme || {};
    return {
      primaryColor: validator.isValidColor(primaryColor) ? primaryColor : undefined,
      backgroundColor: validator.isValidColor(backgroundColor) ? backgroundColor : undefined,
      backgroundImageUri: backgroundImageUri || '/assets/background.svg',
    };
  }, [client]);

  return (
    <ThemeCustomer primaryColor={primaryColor}>
      <div className='app'>
        <div
          className='background'
          style={{ backgroundColor: backgroundColor, backgroundImage: `url(${backgroundImageUri})` }}
        >
          <div className='form-container'>
            <div className='form-content'>
              <Paper className='form-layout'>
                <Logo client={client} />
                <Switch>
                  {Object.values(AlertRouter).map(({ screen: Component, ...item }) => (
                    <Route exact key={item.url} path={item.url} render={() => <Component />} />
                  ))}
                  <Redirect from='/' to={{ pathname: AlertRouter.SessionExpired.url }} />
                </Switch>
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </ThemeCustomer>
  );
};

const mapStateToProps = (state) => ({
  client: state.client,
});

export default connect(mapStateToProps)(PublicLayout);
