export const CHALLENGE_TYPE = {
  ACTIVATION_WEBAUTHN: 'ACTIVATION_WEBAUTHN',
  DELETION_WEBAUTHN: 'DELETION_WEBAUTHN',
  AUTHN_WEBAUTHN: 'AUTHN_WEBAUTHN',
  AUTHN_GET_REDIRECT_URL: 'AUTHN_GET_REDIRECT_URL',
};

export const ERROR_CODE = {
  WEBAUTHN_WAIT_DEVICE_VERIFICATION: 1028,
};
