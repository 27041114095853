import React, { Fragment, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ActionSend } from 'Redux/actions';
import { TextField, Button, Typography } from '@material-ui/core';
import { Alert, Loading } from 'Components';
import { validator } from 'Helper/validate';
import { userService } from 'Services/user';

const CollapseAuth = ({ t, updateMe, ...props }) => {
  const { init, field, onUpdate } = props;

  const [value, setValue] = useState(init);
  const [error, setError] = useState(false);
  const [isLoadingVerify, setIsLoadingVerify] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const [valueCode, setValueCode] = useState('');
  const [errorCode, setErrorCode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const validate = (value, isCode) => {
    if (isCode) {
      return !validator.isValidCode(value);
    }
    if (field === 'email') {
      return !validator.isValidEmail(value);
    }
    if (field === 'phoneNumber') {
      return !validator.isValidPhone(value);
    }
  };

  const handleClickVerify = () => {
    const nextError = validate(value);
    setError(nextError);
    if (error || nextError) return;

    const request = {
      [field]: value,
    };
    let sendRequest = () =>
      new Promise((resolve, reject) => {
        reject();
      });
    if (field === 'email') sendRequest = userService.updateEmailVerify;
    if (field === 'phoneNumber') sendRequest = userService.updatePhoneVerify;

    setIsLoadingVerify(true);
    sendRequest(request)
      .then((response) => {
        setIsVerified(true);
        if (field === 'email') Alert.success(t('Please check your email'));
        if (field === 'phoneNumber') Alert.success(t('Please check your phone'));
      })
      .catch(() => {})
      .then(() => {
        setIsLoadingVerify(false);
      });
  };

  const handlePressKeyVerify = (event) => {
    if (event.key === 'Enter') {
      handleClickVerify();
    }
  };

  const handleClickUpdate = () => {
    const nextError = validate(valueCode, 1);
    setError(nextError);
    if (errorCode || nextError) return;

    const request = {
      [field]: value,
      code: valueCode,
    };
    let sendRequest = () =>
      new Promise((resolve, reject) => {
        reject();
      });
    if (field === 'email') sendRequest = userService.updateEmail;
    if (field === 'phoneNumber') sendRequest = userService.updatePhone;

    setIsLoading(true);
    sendRequest(request)
      .then((response) => {
        updateMe(response);
        onUpdate();

        Alert.success(t('Update information successfully'));
      })
      .catch(() => {})
      .then(() => {
        setIsLoading(false);
      });
  };

  const handlePressKeyUpdate = (event) => {
    if (event.key === 'Enter') {
      handleClickUpdate();
    }
  };

  return (
    <div className='flex-row'>
      {!isVerified ? (
        <Fragment>
          <div className='form-item-update'>
            {field === 'email' && (
              <TextField
                value={value}
                error={error}
                onChange={(event) => {
                  const { value } = event.target;
                  setValue(value);
                  setError(validate(value));
                }}
                onKeyPress={(e) => handlePressKeyVerify(e)}
                style={{ width: 240 }}
              />
            )}
            {field === 'phoneNumber' && (
              <TextField
                value={value}
                error={error}
                onChange={(event) => {
                  const { value } = event.target;
                  setValue(value);
                  setError(validate(value));
                }}
                onKeyPress={(e) => handlePressKeyVerify(e)}
                inputProps={{ maxLength: 10 }}
              />
            )}
          </div>
          <Button color='primary' onClick={() => handleClickVerify()}>
            <Loading visible={isLoadingVerify} /> {t('Next')}
          </Button>
        </Fragment>
      ) : (
        <Fragment>
          <div className='form-item-update'>
            <Typography variant='body1' className='shower mr-36'>
              {value}
            </Typography>
            <div className='form-item-confirm'>
              <Typography variant='body1' className='shower mr-12'>
                {t('Code')}:
              </Typography>
              <TextField
                value={valueCode}
                error={errorCode}
                onChange={(event) => {
                  const { value } = event.target;
                  setValueCode(value);
                  setErrorCode(validate(value, 1));
                }}
                onKeyPress={(e) => handlePressKeyUpdate(e)}
                style={{ width: 70 }}
              />
              <Button color='primary' onClick={() => handleClickUpdate()}>
                <Loading visible={isLoading} /> {t('Save')}
              </Button>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateMe: ActionSend.updateMe,
    },
    dispatch,
  );

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(CollapseAuth);
