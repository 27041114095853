import React, { Fragment, useState, useRef, useMemo, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ActionSend } from 'Redux/actions';
import { Button, Slider } from '@material-ui/core';
import { Row, Col } from 'antd';
import { Loading, Alert } from 'Components';
import { SaveOutlinedIcon } from 'Components/Icons';
import { userService } from 'Services/user';
import ImageEditor from 'react-avatar-editor';

const AvatarEditor = ({ t, updateMe, ...props }) => {
  const { file, onCancel } = props;
  const inputEl = useRef();
  const [scale, setScale] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const image = useMemo(
    () => ({
      width: innerWidth > 536 ? 470 : innerWidth - 64,
      height: innerWidth > 536 ? 470 : innerWidth - 64,
      border: 1,
      borderRadius: 235,
    }),
    [innerWidth],
  );

  const onClickSave = () => {
    if (inputEl) {
      const canvasScaled = inputEl.current.getImageScaledToCanvas();
      const imageUrl = canvasScaled.toDataURL();
      const image = imageUrl.substring(imageUrl.indexOf(';base64,') + 7);

      setIsLoading(true);
      userService
        .updateUserAvatar({ image })
        .then((response) => {
          updateMe(response);
          onCancel();

          Alert.success(t('Update avatar successfully'));
        })
        .catch(() => {})
        .then(() => setIsLoading(false));
    }
  };

  return (
    <Fragment>
      <Row className='mb-24'>
        <Col>
          <ImageEditor ref={inputEl} image={file} {...image} scale={scale} />
          <Slider defaultValue={1} step={0.1} min={1} max={2} onChange={(_, value) => setScale(value)} />
        </Col>
      </Row>
      <Row type='flex' justify='end'>
        <Col>
          <Button variant='outlined' onClick={onCancel} className='mr-12'>
            {t('Cancel')}
          </Button>
          <Button variant='outlined' color='primary' onClick={onClickSave}>
            <Loading visible={isLoading} Icon={SaveOutlinedIcon} /> {t('Save')}
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateMe: ActionSend.updateMe,
    },
    dispatch,
  );

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(AvatarEditor);
