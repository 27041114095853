import React, { Component } from 'react';
import { store } from 'Redux/store';
import { browserHistory } from 'Helper/history';
import { PublicRouter } from 'Navigation/Router';

const withPermission = (requirePermissions) => (WrappedComponent) => {
  return class extends Component {
    componentDidMount() {
      const { client } = store.getState();
      const { permissions = [] } = client;
      if (!requirePermissions.some(r => permissions.indexOf(r) >= 0)) {
        browserHistory.replace({ pathname: PublicRouter.Error.url, state: 403 });
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
};

export { withPermission };
