import {Error} from 'Components';

import Login from 'Screens/Login';
import Login2Step from 'Screens/Login2Step';
import Logout from 'Screens/Logout';
import LoginPasswordless from 'Screens/LoginPasswordless';
import Register from 'Screens/Register';
import Activation from 'Screens/Activation';
import ActivationResend from 'Screens/ActivationResend';
import PasswordRecover from 'Screens/PasswordRecover';
import PasswordUpdate from 'Screens/PasswordUpdate';

import SessionExpired from 'ScreensPrivate/SessionExpired';

import Profile from 'ScreensPrivate/Profile';
import Dashboard from 'ScreensPrivate/Dashboard';
import Authenticator from 'ScreensPrivate/Authenticator';
import Security from 'ScreensPrivate/Security';
import Greeting from 'ScreensPrivate/MFA/Greeting';
import TwoStepMain from 'ScreensPrivate/MFA/Main';
import WebAuthnRegister from 'ScreensPrivate/Webauthn/WebAuthnRegister';
import WebAuthnActivation from 'ScreensPrivate/Webauthn/WebAuthnActivation';
import WebAuthnDeletion from 'ScreensPrivate/Webauthn/WebAuthnDeletion';
import LoginWebAuthn from 'Screens/LoginWebAuthn';
import WebAuthnVerifyDevice from 'Screens/WebAuthnVerifyDevice';
import PasswordExpire from '../Screens/PasswordExpire';
import PasswordComingExpire from '../Screens/PasswordExpire';

export const PublicRouter = {
  Error: {
    url: '/error',
    screen: Error,
  },
  Login: {
    url: '/login',
    screen: Login,
  },
  Login2Step: {
    url: '/login/mfa',
    screen: Login2Step,
  },
  Logout: {
    url: '/logout',
    screen: Logout,
  },
  LoginPasswordless: {
    url: '/login/passwordless',
    screen: LoginPasswordless,
  },
  WebAuthnVerifyDevice: {
    url: '/login/webauthn/verify-device',
    screen: WebAuthnVerifyDevice,
  },
  LoginWebAuthn: {
    url: '/login/webauthn',
    screen: LoginWebAuthn,
  },
  Register: {
    url: '/register',
    screen: Register,
  },
  Activation: {
    url: '/activation',
    screen: Activation,
  },
  ActivationResend: {
    url: '/activation/resend',
    screen: ActivationResend,
  },
  PasswordRecover: {
    url: '/password/recover',
    screen: PasswordRecover,
  },
  PasswordUpdate: {
    url: '/password/update',
    screen: PasswordUpdate,
  },
  PasswordComingExpire: {
    url:'/password/coming-expire',
    screen:PasswordComingExpire,
  }
};

export const AlertRouter = {
  SessionExpired: {
    url: '/alert/session-expired',
    screen: SessionExpired,
  },
};

export const PrivateRouter = {
  Profile: {
    url: '/user/profile',
    screen: Profile,
  },
  UserDashboard: {
    url: '/user/dashboard',
    screen: Dashboard,
  },
  Authenticator: {
    url: '/user/security/authenticator',
    screen: Authenticator,
  },
  Security: {
    url: '/user/security',
    screen: Security,
  },
  Greeting: {
    url: '/user/security/mfa/greeting',
    screen: Greeting,
  },
  TwoStepMain: {
    url: '/user/security/mfa',
    screen: TwoStepMain,
  },
  WebAuthnRegister: {
    url: '/user/webauthn/register',
    screen: WebAuthnRegister,
  },
  WebAuthnActivation: {
    url: '/user/webauthn/activation',
    screen: WebAuthnActivation,
  },
  WebAuthnDeletion: {
    url: '/user/webauthn/deletion',
    screen: WebAuthnDeletion,
  },
};
