import React, { Fragment, useMemo, useEffect, useState, useCallback, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { withPermission } from 'Containers/ScreenPermission';
import { RecaptchaLayout } from 'Containers/RecaptchaLayout';
import { compose } from 'recompose';
import { CLIENT_PERMISSIONS as P } from 'Containers/PublicLayout';
import {
  FormGroup,
  Typography,
  FormControl,
  TextField,
  Divider,
  Button,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Dialog,
  DialogContent,
  DialogActions, DialogTitle,
} from '@material-ui/core';
import { Loading, ColorLink, PasswordPolicyTooltip } from 'Components';
import { userService } from 'Services/user';
import { passwordService } from 'Services/password';
import { browserHistory } from 'Helper/history';
import { validator } from 'Helper/validate';
import { PublicRouter } from 'Navigation/Router';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import vars from 'vars';

const Register = ({ t, challenge, client }) => {
  const { tenant } = client;
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordMatchError, setPasswordMatchError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPasswordPolicy, setLoadingPasswordPolicy] = useState(false);
  const [passwordPolicy, setPasswordPolicy] = useState(validator.defaultPasswordPolicy);
  const [showPassword, setShowPassword] = useState(false);
  const [agreePolicy, setAgreePolicy] = useState(false);
  const [open, setOpen] = useState(false);

  const { buttonColor } = useMemo(() => {
    const { buttonColor } = client.theme || {};
    return {
      buttonColor: validator.isValidColor(buttonColor) ? buttonColor : undefined,
    };
  }, [client]);

  const { brandName, termsUri, privacyUri } = useMemo(() => {
    const {
      NAME,
      TERM_URL,
      POLICY_URL,
    } = (vars.CUSTOM_REGISTER_POLICY_CHECKBOX && vars.CUSTOM_REGISTER_POLICY_CHECKBOX[client.tenantId]) ? vars.CUSTOM_REGISTER_POLICY_CHECKBOX[client.tenantId] : {};
    const { brandName, termsUri, privacyUri } = client.termsPrivacy || {};
    const { tenantBrandName, tenantTermsUri, tenantPrivacyUri } = tenant ? {
      tenantBrandName: tenant.brandName, tenantTermsUri: tenant.termsUri, tenantPrivacyUri: tenant.privacyUri,
    } : {};
    return {
      brandName: brandName || tenantBrandName || NAME,
      termsUri: termsUri || tenantTermsUri || TERM_URL,
      privacyUri: privacyUri || tenantPrivacyUri || POLICY_URL,
    };
  }, [client]);

  const isValidate = () => {
    let isError = false;
    if (validator.isEmpty(name)) {
      setNameError(true);
      isError = true;
    }
    if (!validator.isValidUsername(username)) {
      setUsernameError(true);
      isError = true;
    }
    if (!validator.isValidPassword(password, passwordPolicy)) {
      setPasswordError(true);
      isError = true;
    }
    if (password !== passwordConfirm) {
      setPasswordMatchError(true);
      isError = true;
    }
    return !isError;
  };

  const handleToggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleToggleAgreePolicy = () => {
    setAgreePolicy((prevAgreePolicy) => !prevAgreePolicy);
  };

  const handleClickOpen = () => () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOk = () => {
    setOpen(false);
    setAgreePolicy(true);
  };

  const recaptchaEnabled = (typeof client.recaptchaConfiguration === 'object' && client.recaptchaConfiguration) ? client.recaptchaConfiguration.enabled : false;
  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleClickSubmit = useCallback(async () => {
    if (recaptchaEnabled && !executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const recaptchaToken = recaptchaEnabled ? await executeRecaptcha('getCaptchaToken') : '';
    if (isValidate()) {
      const request = {
        username,
        password,
        name,
        recaptchaToken,
      };
      setIsLoading(true);
      userService
        .register(request)
        .then((response) => {
          browserHistory.push({
            pathname: PublicRouter.Activation.url,
            search: `challenge=${challenge}`,
            state: { username },
          });
        })
        .catch(() => {
        })
        .then(() => setIsLoading(false));
    }
  }, [executeRecaptcha, username, password, name, passwordConfirm]);

  const handlePressKey = (event) => {
    if (event.key === 'Enter') {
      handleClickSubmit();
    }
  };

  useEffect(() => {
    setLoadingPasswordPolicy(true);
    passwordService
      .getPasswordPolicy({ tenantId: client.tenantId })
      .then((res) => {
        setPasswordPolicy(res);
      })
      .catch(() => {
      })
      .finally(() => {
        setLoadingPasswordPolicy(false);
      });
  }, [client, client.tenantId]);

  const passwordBasedForEmailAllowed = client.permissions.includes(P.USERS_EMAIL_REGISTER);
  const passwordBasedForPhoneNumAllowed = client.permissions.includes(P.USERS_PHONE_NUMBER_REGISTER);

  return (
    <Fragment>
      <Typography variant='h5' className='mb-36'>
        {t('Create new account')}
      </Typography>
      <div className='flex-center my-12'>
        <Divider className='flex-1' />
        <span className='mx-12 color-gray text-bolder'>{t('Enter account information')}</span>
        <Divider className='flex-1' />
      </div>
      <FormControl fullWidth className='mb-12'>
        <TextField
          label={t('Name')}
          margin='dense'
          variant='outlined'
          value={name}
          error={nameError}
          onChange={(event) => {
            const { value } = event.target;
            setName(value);
            setNameError(validator.isEmpty(value));
          }}
          onKeyPress={(e) => handlePressKey(e)}
        />
      </FormControl>
      <FormControl fullWidth className='mb-12'>
        <TextField
          label={(passwordBasedForEmailAllowed && passwordBasedForPhoneNumAllowed)
            ? t('Email or phone number') : !passwordBasedForEmailAllowed ? t('Phone number') : t('Email')}
          margin='dense'
          variant='outlined'
          value={username}
          error={usernameError}
          onChange={(event) => {
            const { value } = event.target;
            setUsername(value);
            setUsernameError(!validator.isValidUsername(value));
          }}
          onKeyPress={(e) => handlePressKey(e)}
        />
      </FormControl>
      <FormControl fullWidth className='mb-12'>
        <TextField
          label={t('Password')}
          margin='dense'
          variant='outlined'
          type={showPassword ? 'text' : 'password'}
          value={password}
          error={passwordError}
          onChange={(event) => {
            const { value } = event.target;
            setPassword(value);
            setPasswordError(!validator.isValidPassword(value, passwordPolicy));
          }}
          onKeyPress={handlePressKey}
          InputProps={{
            endAdornment: isLoadingPasswordPolicy ? (
              <InputAdornment position='end' className='color-gray'>
                <Loading visible={true} />
              </InputAdornment>
            ) : (
              <PasswordPolicyTooltip passwordPolicy={passwordPolicy} />
            ),
          }}
        />
      </FormControl>
      <FormControl fullWidth className='mb-6'>
        <TextField
          label={t('Confirm password')}
          margin='dense'
          variant='outlined'
          type={showPassword ? 'text' : 'password'}
          value={passwordConfirm}
          error={passwordMatchError}
          onChange={(event) => {
            const { value } = event.target;
            setPasswordConfirm(value);
            setPasswordMatchError(password !== value);
          }}
          onKeyPress={handlePressKey}
        />
      </FormControl>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox checked={showPassword} onChange={handleToggleShowPassword} name='showPassword' color='primary' />
          }
          label={<span className='color-gray'>{t('Show password')}</span>}
        />
      </FormGroup>
      {
        (client && privacyUri && termsUri) ? (<FormGroup className='mb-12'>
          <FormControlLabel
            control={
              <Checkbox checked={agreePolicy} onChange={handleToggleAgreePolicy} name='agreePolicy' color='primary' />
            }
            className='text-align-justify'
            label={<span className='color-gray'>{t('By checking this box, I confirm that I have read and agree to')} {<a
              href={privacyUri}
              target={'_blank'}>{t('Privacy policy')}</a>} {t('And').toLowerCase()} <a
              href={termsUri}
              target={'_blank'}>{t('Terms of use')}</a> {t('Of').toLowerCase()} {brandName} {t('Including the rights to collect, use, and disclose my personal data in accordance with the applicable laws').toLowerCase()}</span>} />
        </FormGroup>) : (<FormGroup className='mb-24'>
          <FormControlLabel
            control={
              <Checkbox checked={agreePolicy} onChange={handleToggleAgreePolicy} name='agreePolicy' color='primary' />
            }
            className='text-align-justify'
            label={<span className='color-gray'>{t('By checking this box, I confirm that I have read and agree to')} {<a
              onClick={handleClickOpen()}>{t('Privacy policy')}</a>} {t('And').toLowerCase()} <a
              onClick={handleClickOpen()}>{t('Terms of use')}</a> {t('Of').toLowerCase()} {t('TEKO')} {t('Including the rights to collect, use, and disclose my personal data in accordance with the applicable laws').toLowerCase()}</span>}
          />
        </FormGroup>)
      }

      <FormControl fullWidth className='mb-24'>
        <Button disabled={!agreePolicy} color='primary' style={{ color: buttonColor }} variant='contained'
                onClick={() => handleClickSubmit()}>
          <Loading visible={isLoading} /> {t('Create account')}
        </Button>
      </FormControl>
      <FormControl fullWidth className='mb-12'>
        <Typography variant='body1' className='justify-content-center'>
          <ColorLink
            to={{
              pathname: PublicRouter.Login.url,
              search: `challenge=${challenge}`,
            }}>
            {t('Login')}
          </ColorLink>
        </Typography>
      </FormControl>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        className='mb-48'
        fullWidth
      >
        <DialogTitle
          id='scroll-dialog-title'>{t('Privacy policy')} {t('And').toLowerCase()} {t('Terms of use')}</DialogTitle>
        <DialogContent dividers={true} style={{ height: '1000px', overflow: 'hidden', margin: 'unset' }}>
          <iframe
            src={vars.POLICY_URL || 'https://docs.google.com/document/d/1xiD8idzXotgq687LPZLh-2Z4ZrDCk04HA73qZGIAbqs/preview?usp=sharing'}
            style={{ width: '100%', height: '100%', border: 'unset' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOk}>{t('OK')}</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default compose(
  withPermission([P.USERS_EMAIL_REGISTER, P.USERS_PHONE_NUMBER_REGISTER]),
  withTranslation(),
  RecaptchaLayout(),
)(Register);
