import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect, Switch, Route } from 'react-router-dom';
import { Paper, Hidden } from '@material-ui/core';
import { AppHeader, AppMenu, ThemeCustomer } from 'Components';
import { ActionSend } from 'Redux/actions';
import { PrivateRouter, AlertRouter } from 'Navigation/Router';
import { browserHistory } from 'Helper/history';
import { supportLanguage } from 'i18n';
import vars from 'vars';

const PrivateLayout = ({ i18n, ...props }) => {
  const { fetchMe } = props;

  const [isFetched, setIsFetched] = useState(false);

  useEffect(() => {
    supportLanguage(localStorage.getItem('language'));

    if (isFetched) {
      if (!window.TekoID.user.isLoggedIn()) {
        window.TekoID.user.login();
      } else {
        fetchMe();
      }
    } else {
      window.TekoID.init({
        clientId: vars.REACT_APP_CLIENT_ID,
        redirectUri: vars.REACT_APP_REDIRECT_URI,
        scopes: vars.REACT_APP_SCOPES,
        oauthDomain: vars.REACT_APP_AUTH_URL,
        monitorSession: vars.REACT_APP_ENV_MODE === 'product',
      }).then(() => {
        setIsFetched(true);
        window.TekoID.user.events.addUserSessionChanged(() => {
          browserHistory.push(AlertRouter.SessionExpired.url);
        });
      });
    }
  }, [isFetched, fetchMe, i18n]);

  return isFetched && window.TekoID.user.isLoggedIn() ? (
    <ThemeCustomer primaryColor='#4285f4'>
      <div className='app'>
        <div className='background flex-column'>
          <AppHeader />
          <div className='form-container-profile'>
            <div className='form-content-profile'>
              <Paper className='form-layout flex-row'>
                <Hidden smDown>
                  <AppMenu />
                </Hidden>
                <Switch>
                  {Object.values(PrivateRouter).map(({ screen: Component, ...item }) => (
                    <Route exact key={item.url} path={item.url} render={() => <Component />} />
                  ))}
                  <Redirect from='/' to={PrivateRouter.UserDashboard.url} />
                </Switch>
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </ThemeCustomer>
  ) : (
    <div className='app'>
      <div className='background' />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchMe: ActionSend.fetchMe,
    },
    dispatch,
  );

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(PrivateLayout);
