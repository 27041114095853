import _ from 'lodash';

const isEmpty = (string) => {
  return _.isEmpty(_.trim(string));
};

const isEmail = ({ username }) => {
  return username.includes('@');
};

const isValidEmail = (string) => {
  return /^[a-zA-Z0-9_.-]{1,}@[a-zA-Z0-9_-]{2,}(.[a-zA-Z0-9_-]{2,}){1,}$/.test(string);
};

const isValidPhone = (string) => {
  return /^[0-9]{10}$/.test(string);
};

const isValidCode = (string) => {
  return /^[0-9]+$/.test(string);
};

const isValidColor = (colorHex) => {
  return /^#[a-fA-F0-9]{6}$/.test(colorHex);
};

const isValidUsername = (username) => {
  return isValidEmail(username) || isValidPhone(username);
};

const defaultPasswordPolicy = {
  minLength: 6,
  uppercase: 0,
  number: 0,
  special: 0,
};

const isValidPassword = (password, passwordPolicy = { minLength: 6 }) => {
  const { minLength, uppercase, number, special } = passwordPolicy;

  if (minLength && password.length < minLength) return false;

  if (uppercase && uppercase > 0) {
    let countUppercase = 0;
    for (const ch of password) {
      if (ch.toUpperCase() === ch) {
        countUppercase++;
      }
    }
    if (countUppercase < uppercase) return false;
  }

  if (number && number > 0) {
    const numberChars = '0123456789';
    let countNumber = 0;
    for (const ch of password) {
      if (numberChars.includes(ch)) {
        countNumber++;
      }
    }
    if (countNumber < number) return false;
  }

  if (special && special > 0) {
    const specialChars = ' !"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~';
    let countSpecial = 0;
    for (const ch of password) {
      if (specialChars.includes(ch)) {
        countSpecial++;
      }
    }
    if (countSpecial < special) return false;
  }

  return true;
};

export const validator = {
  isEmpty,
  isEmail,
  isValidEmail,
  isValidPhone,
  isValidCode,
  isValidColor,
  isValidUsername,
  defaultPasswordPolicy,
  isValidPassword,
};
