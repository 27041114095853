export const identityError = {
  // # HTTP 400 - Bad Request
  // # Common
  // BAD_REQUEST = (1000, 'Bad Request')
  1000: 'Bad Request',
  // REQUIRED_FIELD = (1001, 'Field `{}` is not defined but should have been')
  1001: 'Bad request - Required field',
  // INVALID_FIELD = (1002, 'Field `{}` is not valid')
  1002: 'Bad request - Invalid field',

  // # Account
  // INVALID_ACCOUNT = (1010, 'This account does not exist')
  1010: 'This account does not exist',
  // UNCONFIRMED_ACCOUNT = (1011, 'Your account has been registered but not activated')
  1011: 'Your account has been registered but not activated',
  // DISABLED_ACCOUNT = (1012, 'Your account has been disabled')
  1012: 'Your account has been disabled',
  // ACTIVATED_ACCOUNT = (1013, 'Your account has already been activated')
  1013: 'Your account has already been activated',
  // INVALID_EMAIL_OR_PHONE_NUMBER = (1014, 'Your phone number, or email address is not valid')
  1014: 'Your phone number, or email address is not valid',
  // INVALID_PHONE_NUMBER = (1015, 'Your phone number is not valid')
  1015: 'Your phone number is not valid',
  // INVALID_EMAIL = (1016, 'Your email address is not valid')
  1016: 'Your email address is not valid',
  // INCORRECT_USERNAME_PASSWORD = (1017, 'Your username, or password is not correct')
  1017: 'Your username, or password is not correct',
  // EXISTENT_USERNAME = (1018, 'The username has already been taken')
  1018: 'The username has already been taken',
  // EXISTENT_PHONE_NUMBER = (1019, 'This phone number is already registered')
  1019: 'This phone number is already registered',
  // EXISTENT_EMAIL = (1020, 'This email address is already registered')
  1020: 'This email address is already registered',
  // NOT_ALLOWED_TO_UPDATE_EMAIL = (1021, 'An email address already existed, you cannot update another email address for this account')
  1021: 'An email address already existed, you cannot update another email address for this account',
  1026: 'Your request has expired',
  1027: 'You do not have any active credentials',
  // INVALID_PASSWORD_RESET = (1031, 'Password should not be last "{}" passwords')
  1031: 'Password has already used',
  // # Verification code
  // INVALID_VERIFICATION_CODE = (1040, 'Your verification code is not valid')
  1040: 'Your verification code is not valid',
  // INCORRECT_USERNAME_OR_ACTIVATION_CODE = (1041, 'Your username, or activation code is not valid')
  1041: 'Your username, or activation code is not valid',
  // INCORRECT_USERNAME_OR_RECOVERY_CODE = (1042, 'Your username, or recovery code is not valid')
  1042: 'Your username, or recovery code is not valid',
  // INCORRECT_PHONE_NUMBER_OR_VERIFICATION_CODE = (1043, 'Your phone number, or verification code is not valid')
  1043: 'Your phone number, or verification code is not valid',

  // # Authentication request
  // MISSING_TENANT_ID = (1050, 'Missing "tenant_id" in configuration')
  1050: "Missing 'tenant_id' in configuration",

  // # Google/Facebook/Apple login
  // INVALID_SOCIAL_AUDIENCE = (1060, 'Claim "aud" associated with the access token has not been registered')
  1060: "Claim 'aud' associated with the access token has not been registered",
  // INVALID_CLIENT = (1061, 'This client does not exist')
  1061: 'This client does not exist',
  // EXISTENT_CLIENT = (1062, 'This client has already been existed')
  1062: 'This client has already been existed',
  // INVALID_ACCESS_TOKEN = (1063, 'The "access_token" value is not valid')
  1063: "The 'access_token' value is not valid",
  // MISSING_GOOGLE_ID = (1064, 'Missing "id" in response that returned by Google')
  1064: "Missing 'id' in response that returned by Google",
  // MISSING_FACEBOOK_ID = (1065, 'Missing "id" in response that returned by Facebook')
  1065: "Missing 'id' in response that returned by Facebook",
  // MISSING_APPLE_ID = (1065, 'Missing "sub" in token that returned by Apple')
  1066: "Missing 'sub' in token that returned by Apple",

  // # Upload file
  // INVALID_IMAGE = (1070, 'Image cannot decode, or is not valid')
  1070: 'Image cannot decode, or is not valid',
  // INVALID__MIME_TYPE = (1071, 'The MIME type of the selected file is not allowed')
  1071: 'The MIME type of the selected file is not allowed',
  // INVALID_FILE_SIZE = (1072, 'The selected file is too large')
  1072: 'The selected file is too large',

  // # Internet banking
  // MISSING_MOBILE_OR_BANK_CODE = (1080, 'Missing "mobile" or "bankcode" in response')
  1080: "Missing 'mobile' or 'bankcode' in response",

  // # HTTP 401 - Unauthorized
  // UNAUTHORIZED = (1100, 'Unauthorized')
  1100: 'Badrequest - Unauthorized',
  // MISSING_AUTHORIZATION_HEADER = (1101, 'Missing "Authorization" in headers')
  1101: "Missing 'Authorization' in headers",
  // UNAUTHORIZED_USER = (1102, 'User has not been authenticated, or user credentials are not valid')
  1102: 'User has not been authenticated, or user credentials are not valid',
  // UNAUTHORIZED_PASSWORD_EXPIRED = (1103, 'Password has expired')
  1103: 'Password expired',
  // # HTTP 403 - Forbidden
  // FORBIDDEN = (1300, 'Request Forbidden')

  // ACTION_NOT_ALLOWED = (1301, 'Your client does not have permission "{}" to do this action')
  1301: 'Your client does not have permission to do this action',

  // BLOCKED_AUTHENTICATE_USER = (1302, 'User has been blocked from authentication')
  1302: 'User has been blocked from authentication',

  // DISALLOWED_BY_TENANT = (1303, 'Your tenant does not allow to do this action')
  1303: 'Your tenant does not allow to do this action',

  // # HTTP 404 - Not Found
  // NOT_FOUND = (1400, 'Not Found')

  // # HTTP 405 - Method Not Allowed
  // METHOD_NOT_ALLOWED = (1500, 'Method Not Allowed')

  // # HTTP 406 - Not Acceptable
  // NOT_ACCEPTABLE = (1600, 'Not Acceptable')

  // # HTTP 415 - Unsupported Media Type
  // UNSUPPORTED_MEDIA_TYPE = (1700, 'Unsupported Media Type')

  // # HTTP 429 - Too Many Requests
  // TOO_MANY_REQUEST = (1800, 'Too Many Requests')
};
