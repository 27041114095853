import React, { Fragment, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { FormControlLabel, Switch, Typography } from '@material-ui/core';
import { Collapse } from 'antd';
import { browserHistory } from 'Helper/history';
import { PrivateRouter } from 'Navigation/Router';

import securitycheckupScene from 'Assets/images/securitycheckup_scene.png';
import signinScene from 'Assets/images/signin_scene.png';

const Security = ({ t, profile }) => {
  const isSupport2Step = useMemo(() => {
    const { registrationMethod } = profile;
    if (!registrationMethod) return true;
    return typeof registrationMethod === 'string' && registrationMethod.toLowerCase() === 'password';
  }, [profile]);

  const handleClick2Step = () => {
    if (profile.enableMfa) {
      browserHistory.push({
        pathname: PrivateRouter.Authenticator.url,
        state: { enabled: true },
      });
    } else {
      browserHistory.push(PrivateRouter.Greeting.url);
    }
  };

  return (
    <Fragment>
      <div className='flex-1 mx-12'>
        <div className='text-align-center'>
          <Typography variant='h5' className='mb-12'>
            {t('Security')}
          </Typography>
          <Typography variant='body1' color='textSecondary' className='mb-12'>
            {t('Settings and recommendations to help you keep your account secure')}
          </Typography>
        </div>
        <div className='justify-content-center'>
          <div style={{ flex: 1, maxWidth: 720 }}>
            <Collapse expandIconPosition='right' activeKey={[]} className='read-only'>
              <Collapse.Panel
                className='ant-collapse-panel-header'
                header={
                  <div className='justify-content-between'>
                    <div>
                      <Typography variant='h6'>{t('Security issues')}</Typography>
                      <Typography variant='body2' color='textSecondary' style={{ maxWidth: 320 }}>
                        {t('Protect your account now by resolving these issues')}
                      </Typography>
                    </div>
                    <img src={securitycheckupScene} alt='' />
                  </div>
                }
              />
              <Collapse.Panel
                showArrow={false}
                header={<CollapseHeader title={<span style={{ color: 'darkgreen' }}>{t('No issues found')}</span>} />}
              />
            </Collapse>
            <div className='mb-48' />

            <Collapse expandIconPosition='right' activeKey={[]} className='read-only'>
              <Collapse.Panel
                className='ant-collapse-panel-header'
                header={
                  <div className='justify-content-between'>
                    <Typography variant='h6' className='justify-content-between'>
                      {t('Signing in configuration')}
                    </Typography>
                    <img src={signinScene} alt='' />
                  </div>
                }
              />
              <Collapse.Panel
                showArrow={false}
                header={<CollapseHeader title={t('Password')} content='●●●●●●●●●●' />}
              />
              {isSupport2Step ? (
                <Collapse.Panel
                  showArrow
                  header={
                    <CollapseHeader
                      title={t('2-Step Verification')}
                      content={
                        <FormControlLabel
                          control={<Switch color='primary' checked={Boolean(profile.enableMfa)} />}
                          label={Boolean(profile.enableMfa) ? t('ON') : t('OFF')}
                        />
                      }
                      onClick={() => handleClick2Step()}
                      className='cursor-pointer'
                    />
                  }
                />
              ) : (
                <Collapse.Panel
                  showArrow={false}
                  header={
                    <CollapseHeader
                      title={t('2-Step Verification')}
                      content={
                        <Typography variant='body2' color='error'>
                          {t('Registration does not support 2-Step Verification')}
                        </Typography>
                      }
                    />
                  }
                />
              )}
              <Collapse.Panel
                showArrow={false}
                header={
                  <CollapseHeader
                    title={t('Registration')}
                    content={<Typography className='text-capitalize'>{profile.registrationMethod}</Typography>}
                  />
                }
              />
            </Collapse>
            <div className='mb-48' />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const CollapseHeader = ({ title, content, onClick, className = '' }) => (
  <div
    className={`form-item-header medium ${className}`}
    onClick={() => {
      if (typeof onClick === 'function') {
        onClick();
      }
    }}>
    <div className='form-item-label'>{title}</div>
    <div className='form-item-content'>
      <Typography variant='body1'>{content}</Typography>
    </div>
  </div>
);

const mapStateToProps = (state) => ({
  profile: state.user,
});

export default compose(withTranslation(), connect(mapStateToProps))(Security);
