import React from 'react';
import './Border.scss';

const Border = ({ children = null, className = '', style = {} }) => (
  <div className={`BorderBox-container ${className}`} style={{ ...style }}>
    {children}
  </div>
);

Border.Group = ({ children = null, className = '', style = {} }) => (
  <div className={`BorderGroup-container ${className}`} style={{ ...style }}>
    {children}
  </div>
);

export default Border;
