import React, { Fragment, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { CLIENT_PERMISSIONS as P } from 'Containers/PublicLayout';
import { Typography, FormControl, TextField, Divider, Button, LinearProgress } from '@material-ui/core';
import { Icon, Modal } from 'antd';
import { FacebookLogin, ColorLink, ExternalLink, Loading, Alert } from 'Components';
import { userService } from 'Services/user';
import { socialService } from 'Services/social';
import { browserHistory, redirect } from 'Helper/history';
import { validator } from 'Helper/validate';
import { PublicRouter } from 'Navigation/Router';

import GoogleSvg from 'Assets/social/google.svg';
import FacebookSvg from 'Assets/social/facebook.svg';
import DevicesIcon from '@material-ui/icons/Devices';

const Progress = ({ visible, position }) => (
  <LinearProgress
    style={{
      position: 'absolute',
      left: 0,
      right: 0,
      [position]: 0,
      display: visible ? 'inherit' : 'none',
    }}
  />
);

const Login = ({ t, challenge, client, oidcContext }) => {
  const { ggApp: google, fbApp: facebook, permissions, tenant } = client;
  const { loginHint } = oidcContext;
  const isGoogle = typeof google === 'object' && google;
  const isFacebook = typeof facebook === 'object' && facebook;

  const [username, setUsername] = useState(loginHint || '');
  const [usernameError, setUsernameError] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFacebook, setIsLoadingFacebook] = useState(false);
  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);
  const [passwordWarningVisible, setPasswordWarningVisible] = useState(false);

  const { buttonColor, hyperlink, hyperlinkedText } = useMemo(() => {
    const { buttonColor, hyperlink, hyperlinkedText } = client.theme || {};
    return {
      buttonColor: validator.isValidColor(buttonColor) ? buttonColor : undefined,
      hyperlink,
      hyperlinkedText,
    };
  }, [client]);

  const isValidate = () => {
    let isError = false;
    if (!validator.isValidUsername(username)) {
      setUsernameError(true);
      isError = true;
    }
    if (!validator.isValidPassword(password)) {
      setPasswordError(true);
      isError = true;
    }
    return !isError;
  };

  const handleClickSubmit = () => {
    if (isValidate()) {
      const request = {
        challenge,
        username,
        password,
      };
      setIsLoading(true);
      userService
        .login(request)
        .then((response) => {
          const { mfaRequired, tokenType, mfaToken, redirectTo, passwordWarning } = response;
          if (passwordWarning) {
            browserHistory.push({
              pathname: PublicRouter.PasswordComingExpire.url,
              data: { ...response, username },
              isExpired: false,
            });
          } else {
            if (mfaRequired) {
              browserHistory.push({
                pathname: PublicRouter.Login2Step.url,
                search: `challenge=${challenge}`,
                state: {
                  tokenType: tokenType,
                  mfaToken: mfaToken,
                  username: username,
                  challenge: challenge,
                },
              });
            } else if (redirectTo) {
              redirect(redirectTo);
            } else {
              Alert.error(t('Sorry, something went wrong there'));
            }
          }
        })
        .catch(() => {})
        .then(() => setIsLoading(false));
    }
  };

  const handlePressKey = (event) => {
    if (event.key === 'Enter') {
      handleClickSubmit();
    }
  };

  const responseFacebook = (response) => {
    if (response && response.hasOwnProperty('accessToken')) {
      const { accessToken } = response;
      const request = {
        authorizer: 'facebook',
        appId: facebook.clientId,
        challenge,
        accessToken,
      };
      setIsLoadingFacebook(true);
      socialService
        .login(request)
        .then((response) => {
          const { redirectTo } = response;
          redirect(redirectTo);
        })
        .catch(() => {})
        .then(() => setIsLoadingFacebook(false));
    }
  };

  const havePermission = (...items) => {
    if (!Array.isArray(permissions)) return false;

    return items.some((item) => permissions.includes(item));
  };

  const passwordlessAllowed =
    tenant && tenant.passwordlessAuthenticationAllowed && havePermission(P.PASSWORDLESS_LOGIN);
  const passwordBasedAllowedByTenant = tenant && tenant.passwordBasedAuthenticationAllowed;
  const passwordBasedForEmailAllowed = havePermission(P.USERS_EMAIL_LOGIN) && passwordBasedAllowedByTenant;
  const passwordBasedForPhoneNumAllowed = havePermission(P.USERS_PHONE_NUMBER_LOGIN) && passwordBasedAllowedByTenant;
  const registrationForEmailAllowed = tenant && tenant.registrationAllowed && havePermission(P.USERS_EMAIL_REGISTER);
  const registrationForPhoneNumAllowed =
    tenant && tenant.registrationAllowed && havePermission(P.USERS_PHONE_NUMBER_REGISTER);
  const resetPasswordAllowed = tenant && tenant.resetPasswordAllowed && havePermission(P.PASSWORD_RESET);
  const webauthnAllowed =
    tenant && tenant.webauthnAllowed && havePermission(P.WEBAUTHN_LOGIN) && window.PublicKeyCredential;
  return (
    <Fragment>
      <Typography variant='h5' className='mb-36'>
        {t('Login')}
      </Typography>
      {passwordBasedForEmailAllowed && passwordBasedForPhoneNumAllowed && (
        <>
          <FormControl fullWidth className='mb-12'>
            <TextField
              disabled={loginHint}
              label={t('Email or phone number')}
              margin='dense'
              variant='outlined'
              value={username}
              error={usernameError}
              onChange={(event) => {
                const { value } = event.target;
                setUsername(value);
                setUsernameError(!validator.isValidUsername(value));
              }}
              onKeyPress={(e) => handlePressKey(e)}
            />
          </FormControl>
          <FormControl fullWidth className='mb-12'>
            <TextField
              label={t('Password')}
              margin='dense'
              variant='outlined'
              type='password'
              value={password}
              error={passwordError}
              onChange={(event) => {
                const { value } = event.target;
                setPassword(value);
                setPasswordError(!validator.isValidPassword(value));
              }}
              onKeyPress={(e) => handlePressKey(e)}
            />
          </FormControl>
        </>
      )}
      {passwordBasedForPhoneNumAllowed !== passwordBasedForEmailAllowed && (
        <>
          <FormControl fullWidth className='mb-12'>
            <TextField
              label={passwordBasedForPhoneNumAllowed ? t('Phone number') : t('Email')}
              margin='dense'
              variant='outlined'
              value={username}
              error={usernameError}
              onChange={(event) => {
                const { value } = event.target;
                setUsername(value);
                setUsernameError(!validator.isValidUsername(value));
              }}
              onKeyPress={(e) => handlePressKey(e)}
            />
          </FormControl>
          <FormControl fullWidth className='mb-12'>
            <TextField
              label={t('Password')}
              margin='dense'
              variant='outlined'
              type='password'
              value={password}
              error={passwordError}
              onChange={(event) => {
                const { value } = event.target;
                setPassword(value);
                setPasswordError(!validator.isValidPassword(value));
              }}
              onKeyPress={(e) => handlePressKey(e)}
            />
          </FormControl>
        </>
      )}
      {passwordBasedAllowedByTenant && (passwordBasedForEmailAllowed || passwordBasedForPhoneNumAllowed) && (
        <>
          {resetPasswordAllowed && (
            <FormControl fullWidth className='mb-12'>
              <Typography
                variant='body2'
                className={
                  havePermission(P.USERS_EMAIL_LOGIN) || havePermission(P.USERS_PHONE_NUMBER_LOGIN)
                    ? 'justify-content-end'
                    : 'justify-content-center'
                }>
                <ColorLink
                  to={{
                    pathname: PublicRouter.PasswordRecover.url,
                    search: `challenge=${challenge}`,
                  }}>
                  {t('Forgot/Reset password')}
                </ColorLink>
              </Typography>
            </FormControl>
          )}
          <FormControl fullWidth className='mb-12 justify-content-end' style={{ flexDirection: 'row' }}>
            <span style={{ marginRight: 2 }}>{t('Account already exists?')}</span>
            <ColorLink
              to={{
                pathname: PublicRouter.ActivationResend.url,
                search: `challenge=${challenge}`,
              }}>
              {t('Activate')}
            </ColorLink>
          </FormControl>
          <FormControl fullWidth className='mb-12'>
            <Button color='primary' style={{ color: buttonColor }} variant='contained' onClick={handleClickSubmit}>
              <Loading visible={isLoading} /> {t('Log in')}
            </Button>
          </FormControl>
        </>
      )}
      {(registrationForEmailAllowed || registrationForPhoneNumAllowed) && (
        <FormControl fullWidth className='mb-12'>
          <Button variant='outlined'>
            <ColorLink
              to={{
                pathname: PublicRouter.Register.url,
                search: `challenge=${challenge}`,
              }}>
              {t('Create new account')}
            </ColorLink>
          </Button>
        </FormControl>
      )}
      {(havePermission(P.SOCIAL_GOOGLE_LOGIN, P.SOCIAL_FACEBOOK_LOGIN) || passwordlessAllowed) &&
        (passwordBasedForPhoneNumAllowed ||
          passwordBasedForEmailAllowed ||
          registrationForEmailAllowed ||
          registrationForPhoneNumAllowed ||
          resetPasswordAllowed) && (
          <div className='flex-center my-12'>
            <Divider className='flex-1' />
            <span className='mx-12 color-gray text-bolder'>{t('Or login with')}</span>
            <Divider className='flex-1' />
          </div>
        )}
      <FormControl fullWidth className='flex-center' style={{ flexDirection: 'row' }}>
        {isGoogle && havePermission(P.SOCIAL_GOOGLE_LOGIN) && (
          <>
            <div className='flex-center box-icon' style={{ position: 'relative' }}>
              <img className='icon' alt='Google' src={GoogleSvg} style={{ width: 34, marginBottom: 5 }} />
              <span>Google</span>
              <Progress visible={isLoadingGoogle} position='bottom' />
              <div id='google-login' />
            </div>
          </>
        )}
        {isFacebook && havePermission(P.SOCIAL_FACEBOOK_LOGIN) && (
          <FacebookLogin
            appId={facebook.clientId}
            callback={responseFacebook}
            render={(facebook) => (
              <>
                <div className='flex-center box-icon'>
                  <img
                    className='icon'
                    alt='Facebook'
                    src={FacebookSvg}
                    style={{ width: 34, marginBottom: 5 }}
                    onClick={facebook.onClick}
                  />
                  <span>Facebook</span>
                </div>
                <Progress visible={isLoadingFacebook} position='bottom' />
              </>
            )}
          />
        )}
        {passwordlessAllowed && (
          <div className='flex-center box-icon'>
            <Icon
              className='icon'
              type='phone'
              theme='twoTone'
              twoToneColor='#3fadd4'
              style={{ fontSize: 34, marginBottom: 5, transform: 'scaleX(-1)' }}
              onClick={() =>
                browserHistory.push({
                  pathname: PublicRouter.LoginPasswordless.url,
                  search: `challenge=${challenge}`,
                })
              }
            />
            <span>{t('Phone number')}</span>
          </div>
        )}
        {webauthnAllowed && (
          <div className='flex-center box-icon'>
            <DevicesIcon
              className='icon'
              style={{ color: '#52c41a', fontSize: 34, marginBottom: 5, transform: 'scaleX(-1)' }}
              onClick={() =>
                browserHistory.push({
                  pathname: PublicRouter.LoginWebAuthn.url,
                  search: `challenge=${challenge}`,
                })
              }
            />
            <span>{t('Device')}</span>
          </div>
        )}
      </FormControl>
      <div className={hyperlink && hyperlinkedText ? 'mb-12' : 'mb-24'} />
      {hyperlink && hyperlinkedText && (
        <Fragment>
          <ExternalLink href={hyperlink}> {hyperlinkedText} </ExternalLink>
          <div className='mb-12' />
        </Fragment>
      )}
      <Modal visible={passwordWarningVisible} onCancel={() => setPasswordWarningVisible(false)} />
    </Fragment>
  );
};

export default withTranslation()(Login);
