import { client, authClient } from 'Services/clients';

const userAPI = '/api/v1/users';
const userAPILogout = '/api/v1/requests/logout';

const login = (request) => client.post(`${userAPI}/login`, { ...request });
const logout = ({ challenge, ...request }) => client.post(`${userAPILogout}/${challenge}`, { ...request });
const register = (request) => client.post(`${userAPI}/register`, { ...request });

const confirmUser = (request) => client.post(`${userAPI}/confirm`, { ...request });
const resendActivationCode = (request) => client.post(`${userAPI}/activation/resend`, { ...request });

const getUserInfo = () => authClient.get(`/api/v1/me`);
const getTenantInfo = () => authClient.get(`/api/v1/me/tenant`);
const updateUserInfo = (request) => authClient.patch(`/api/v1/me`, { ...request });
const updateUserAvatar = (request) => authClient.post(`/api/v1/me/avatar`, { ...request });

const updateEmail = (request) => authClient.post(`/api/v1/me/email/update`, { ...request });
const updateEmailVerify = (request) => authClient.post(`/api/v1/me/email/verify`, { ...request });
const updatePhone = (request) => authClient.post(`/api/v1/me/phoneNumber/update`, { ...request });
const updatePhoneVerify = (request) => authClient.post(`/api/v1/me/phoneNumber/verify`, { ...request });

const deleteAccount = (request) => authClient.delete(`/api/v1/me`);

export const userService = {
  login,
  logout,
  register,

  confirmUser,
  resendActivationCode,

  getUserInfo,
  getTenantInfo,
  updateUserInfo,
  updateUserAvatar,

  updateEmail,
  updateEmailVerify,
  updatePhone,
  updatePhoneVerify,

  deleteAccount,
};
