import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { Typography, FormControl, TextField, Divider, Button, Chip, Avatar } from '@material-ui/core';
import { ColorLink, Loading, Alert } from 'Components';
import { ArrowBackIcon } from 'Components/Icons';
import { mfaService } from 'Services/mfa';
import { browserHistory, redirect } from 'Helper/history';
import { validator } from 'Helper/validate';
import { t as tr } from 'Helper/translator';
import { PublicRouter } from 'Navigation/Router';

const Login = ({ t, client }) => {
  const { state = {} } = browserHistory.location;
  const { mfaToken, tokenType, username, challenge } = state;

  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState(false);

  const [isLoadingSend, setIsLoadingSend] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [counter, setCounter] = useState(0);

  const buttonColor = useMemo(() => {
    const buttonColor = client && client.theme && client.theme.buttonColor;
    return validator.isValidColor(buttonColor) ? buttonColor : undefined;
  }, [client]);

  const isValidate = () => {
    let isError = false;
    if (!validator.isValidCode(code)) {
      setCodeError(true);
      isError = true;
    }
    return !isError;
  };

  const handleClickSend = () => {
    if (counter > 0) return;

    const payload = {
      tokenType,
      mfaToken,
    };
    setIsLoadingSend(true);
    mfaService
      .triggerChallenge(payload)
      .then((response) => {
        if (tokenType === 'totp') Alert.success(tr('Please check Google Authenticator'));
        if (tokenType === 'sms') Alert.success(tr('Please check your phone'));
        if (tokenType === 'email') Alert.success(tr('Please check your email'));

        setCounter(60);
      })
      .catch(() => {})
      .then(() => setIsLoadingSend(false));
  };

  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => {
        setCounter((counter) => counter - 1);
      }, 1000);
    }
  }, [counter]);

  const handleClickSubmit = () => {
    if (isValidate()) {
      const payload = {
        tokenType,
        mfaToken,
        otp: code,
        challenge: challenge
      };
      setIsLoadingSubmit(true);
      mfaService
        .login(payload)
        .then((response) => {
          const { redirectTo } = response;
          if (redirectTo) {
            redirect(redirectTo);
          } else {
            Alert.error(t('Sorry, something went wrong there'));
          }
        })
        .catch(() => {})
        .then(() => setIsLoadingSubmit(false));
    }
  };

  const handlePressKey = (event) => {
    if (event.key === 'Enter') {
      handleClickSubmit();
    }
  };

  useEffect(() => {
    if (mfaToken && tokenType) {
      if (tokenType === 'totp') Alert.success(tr('Please check Google Authenticator'));
      if (tokenType === 'sms') Alert.success(tr('Please check your phone'));
      if (tokenType === 'email') Alert.success(tr('Please check your email'));
    } else {
      browserHistory.replace({ pathname: PublicRouter.Error.url, state: 500 });
    }
  }, [mfaToken, tokenType]);

  return (
    <Fragment>
      <Typography variant='h5' className='mb-36'>
        {t('2-Step Verification')}
      </Typography>
      <Chip size='medium' avatar={<Avatar />} label={username || ''} variant='outlined' />
      <div className='mb-12' />

      <div className='flex-center my-12'>
        <Divider className='flex-1' />
        <span className='mx-12 color-gray text-bolder'>{t('Enter the 2-Step Verification code')}</span>
        <Divider className='flex-1' />
      </div>

      <FormControl fullWidth className='mt-12 mb-36'>
        <TextField
          autoFocus
          label={t('Code')}
          variant='outlined'
          value={code}
          error={codeError}
          onChange={(event) => {
            const { value } = event.target;
            setCode(value);
            setCodeError(!validator.isValidCode(value));
          }}
          onKeyPress={(e) => handlePressKey(e)}
        />
      </FormControl>
      <div className='justify-content-between align-items-end mb-24'>
        <Typography>
          <ColorLink className='align-items-center' disabled={counter > 0} onClick={() => handleClickSend()}>
            <Loading visible={isLoadingSend} />
            {counter > 0 && <span style={{ marginRight: 8 }}>({counter})</span>}
            {t('Resend verification code')}
          </ColorLink>
        </Typography>

        <Button
          variant='contained'
          color='primary'
          onClick={() => handleClickSubmit()}
          style={{ minWidth: 120, color: buttonColor }}
        >
          <Loading visible={isLoadingSubmit} /> {t('Next')}
        </Button>
      </div>
      <div style={{ position: 'absolute', top: 32, cursor: 'pointer' }} title={t('Back')}>
        <ColorLink
          onClick={() => {
            browserHistory.goBack();
          }}
        >
          <ArrowBackIcon />
        </ColorLink>
      </div>
    </Fragment>
  );
};

export default withTranslation()(Login);
