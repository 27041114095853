import React, { Component, useMemo } from 'react';
import { store } from 'Redux/store';
import {
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';

const RecaptchaLayout = () => (WrappedComponent) => {
  return class extends Component {
    render() {
      const { client } = this.props;
      const { enabled, siteKey } = typeof client.recaptchaConfiguration === 'object' && client.recaptchaConfiguration || { enabled: false, siteKey: '' };
      if (enabled) {
        return (
          <GoogleReCaptchaProvider
            reCaptchaKey={siteKey}>
              <WrappedComponent {...this.props} />
          </GoogleReCaptchaProvider>
        );
      } else {
        return <WrappedComponent {...this.props} />
      }
    }
  };
};

export { RecaptchaLayout };
