import { client } from 'Services/clients';

const passwordAPI = '/api/v1/password';

const recoverPassword = (request) => client.post(`${passwordAPI}/recover`, { ...request });
const resetPassword = (request) => client.post(`${passwordAPI}/reset`, { ...request });

const getPasswordPolicy = (request) => client.get(`/api/v1/tenants/${request.tenantId}/password-policy`);

export const passwordService = {
  recoverPassword,
  resetPassword,

  getPasswordPolicy,
};
