import React from 'react';
import { Link, withStyles } from '@material-ui/core';


const CustomLink = (props) => {
  return <Link {...props} target='_blank' rel='noreferrer' underline='none'/>;
};

const ExternalLink = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
}))(CustomLink);

export default ExternalLink;
