import axios from 'axios';
import Qs from 'qs';
import Hs from 'humps';
import { Alert } from 'Components';
import { PublicRouter } from 'Navigation/Router';
import { store } from 'Redux/store';
import { browserHistory } from 'Helper/history';
import { identityError } from 'Services/clients/error';
import { t } from 'Helper/translator';
import vars from 'vars';
import { ERROR_CODE } from 'Constant/constant';

const baseURL = vars.REACT_APP_API_URL;
const client = axios.create({
  baseURL,
  transformRequest: [(data) => Hs.decamelizeKeys(data), ...axios.defaults.transformRequest],
  transformResponse: [...axios.defaults.transformResponse, (data) => Hs.camelizeKeys(data)],
  paramsSerializer: (params) => Qs.stringify(Hs.decamelizeKeys(params), { arrayFormat: 'brackets' }),
});

const controlError = (exception) => {
  if (!exception.response) {
    Alert.error(t('Cannot connect to Server'));
  } else {
    if (exception.response.status < 500) {
      const { error, message } = exception.response.data;
      if (typeof error === 'object') {
        if (error.code === ERROR_CODE.WEBAUTHN_WAIT_DEVICE_VERIFICATION) {
          return { code: ERROR_CODE.WEBAUTHN_WAIT_DEVICE_VERIFICATION };
        }
        Alert.error(identityError[error.code] ? t(identityError[error.code]) : error.message);
      } else {
        Alert.error(message);
      }
    } else {
      browserHistory.push({ pathname: PublicRouter.Error.url, state: 500 });
      Alert.error(t('Internal Server Error'));
    }
  }
  return Promise.reject(exception);
};

const JSONbigNative = require('json-bigint')({ useNativeBigInt: true });

const convertBigintToStr = (obj) => {
  if (!obj) {
    return obj;
  }
  if (typeof obj == 'bigint') {
    return obj.toString();
  }
  if (typeof obj == 'object') {
    let copiedObj = JSONbigNative.parse(JSONbigNative.stringify(obj));
    for (const [key, value] of Object.entries(obj)) {
      copiedObj[key] = convertBigintToStr(value);
    }
    return copiedObj;
  }
  if (Array.isArray(obj)) {
    return obj.map((item) => convertBigintToStr(item));
  }
  return obj;
};

client.interceptors.response.use((response) => response.data, controlError);
client.interceptors.request.use(
  (config) => {
    const { client = {} } = store.getState();
    config.data = {
      ...config.data,
      clientId: client.clientId,
    };
    return config;
  },
  (error) => Promise.reject(error),
);

const authClient = axios.create({
  baseURL,
  transformRequest: [(data) => Hs.decamelizeKeys(data), ...axios.defaults.transformRequest],
  transformResponse: [...axios.defaults.transformResponse, (data) => Hs.camelizeKeys(data)],
  paramsSerializer: (params) => Qs.stringify(Hs.decamelizeKeys(params), { arrayFormat: 'brackets' }),
});
authClient.interceptors.response.use((response) => response.data, controlError);
authClient.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${window.TekoID.user.getAccessToken()}`;
    return config;
  },
  (error) => Promise.reject(error),
);

const bigIntAuthClient = axios.create({
  baseURL,
  transformRequest: [(data) => Hs.decamelizeKeys(data), ...axios.defaults.transformRequest],
  transformResponse: [
    (data) => {
      try {
        return convertBigintToStr(JSONbigNative.parse(data));
      } catch (err) {
        return data;
      }
    },
    ...axios.defaults.transformResponse,
    (data) => Hs.camelizeKeys(data),
  ],
  paramsSerializer: (params) => Qs.stringify(Hs.decamelizeKeys(params), { arrayFormat: 'brackets' }),
});
bigIntAuthClient.interceptors.response.use((response) => response.data, controlError);
bigIntAuthClient.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${window.TekoID.user.getAccessToken()}`;
    return config;
  },
  (error) => Promise.reject(error),
);

export { client, authClient, bigIntAuthClient };
