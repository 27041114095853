import React from 'react';

const Policy = () => {
  const Teko = (
    <a href='https://teko.vn' rel='noopener noreferrer' target='_blank'>
      Teko
    </a>
  );
  const VnLife = (
    <a href='https://vnlife.vn' rel='noopener noreferrer' target='_blank'>
      VnLife
    </a>
  );

  return (
    <div
      className='app'
      style={{ padding: '50px', backgroundImage: 'url(/assets/background.svg)', backgroundColor: 'aliceblue' }}
    >
      <p className='c2'>
        <span className='c6'>{Teko} </span>
        <span>/ </span>
        <span className='c6'>{VnLife}</span>
        <span className='c5'>&nbsp;cam kết sẽ bảo mật những thông tin mang tính riêng tư của bạn. </span>
      </p>
      <p className='c2 c3'>
        <span className='c5' />
      </p>
      <p className='c2'>
        <span className='c5'>
          Bạn vui lòng đọc bản “Chính sách bảo mật” dưới đây để hiểu hơn những cam kết mà chúng tôi thực hiện, nhằm tôn
          trọng và bảo vệ quyền lợi của người truy cập:
        </span>
      </p>
      <h1 className='c8' id='h.j6yanaj8nxm4'>
        <span className='c4'>1. Mục đích và phạm vi thu thập</span>
      </h1>
      <p className='c2'>
        <span>Để truy cập và sử dụng một số dịch vụ tại </span>
        <span className='c6'>{Teko}</span>
        <span>&nbsp;và các công ty thuộc tập đoàn </span>
        <span className='c6'>{VnLife}</span>
        <span>(Phong Vũ, Vnshop, ... sau đây gọi chung là </span>
        <span className='c6'>{VnLife}</span>
        <span>)</span>
        <span>
          , bạn có thể được yêu cầu đăng ký tài khoản với chúng tôi bằng thông tin cá nhân (Email, Họ tên, Số điện
          thoại,...). Mọi thông tin khai báo phải đảm bảo tính chính xác và hợp pháp.{' '}
        </span>
        <span className='c6'>{VnLife}</span>
        <span className='c5'>&nbsp;không chịu mọi trách nhiệm liên quan đến pháp luật của thông tin khai báo. </span>
      </p>
      <p className='c2 c3'>
        <span className='c5' />
      </p>
      <p className='c2'>
        <span>
          Chúng tôi cũng có thể thu thập thông tin về số lần viếng thăm, bao gồm số trang bạn xem, số links (liên kết)
          bạn click. Chúng tôi cũng thu thập các thông tin mà trình duyệt Web (Browser) bạn sử dụng mỗi khi truy cập vào{' '}
        </span>
        <span className='c6'>{VnLife}</span>
        <span className='c5'>
          , bao gồm: địa chỉ IP, loại Browser, ngôn ngữ sử dụng, thời gian và những địa chỉ mà Browser truy xuất đến.
        </span>
      </p>
      <h1 className='c8' id='h.qbge2ofxc6hs'>
        <span className='c4'>2. Phạm vi sử dụng thông tin</span>
      </h1>
      <p className='c2'>
        <span className='c6'>{VnLife} </span>
        <span className='c5'>
          &nbsp;thu thập và sử dụng thông tin cá nhân bạn với mục đích phù hợp và hoàn toàn tuân thủ nội dung của “Chính
          sách bảo mật” này. Cụ thể: Khi cần thiết, chúng tôi có thể sử dụng những thông tin này vào các mục đích:
        </span>
      </p>
      <ul className='c11 lst-kix_l6jbj1wrstfp-0 start'>
        <li className='c1 li-bullet-0'>
          <span className='c5'>
            Gửi các thông tin Email /tin nhắn SMS cần cho quản lý tài khoản: quá trình đăng ký, xác thực, thay đổi
            password, khôi phục tài khoản..
          </span>
        </li>
        <li className='c1 li-bullet-0'>
          <span className='c5'>
            Xử lý đơn hàng: gửi Email, gọi điện/tin nhắn xác nhận việc đặt hàng, thông báo về trạng thái đơn hàng &amp;
            thời gian giao hàng, xác nhận việc huỷ đơn hàng (nếu có).
          </span>
        </li>
        <li className='c1 li-bullet-0'>
          <span className='c5'>Gửi thông tin về bảo hành sản phẩm.</span>
        </li>
        <li className='c1 li-bullet-0'>
          <span>Thông tin trao thưởng (của </span>
          <span className='c6'>{VnLife}</span>
          <span className='c5'>hoặc đối tác).</span>
        </li>
        <li className='c1 li-bullet-0'>
          <span className='c5'>
            Gửi thông tin cho công ty tài chính để tiếp nhận, thẩm định &amp; duyệt hồ sơ trả góp.
          </span>
        </li>
        <li className='c1 li-bullet-0'>
          <span className='c5'>Gửi thông tin cho đối tác giao vận (Tên / Số điện thoại) để liên lạc khi giao hàng</span>
        </li>
        <li className='c1 li-bullet-0'>
          <span className='c5'>
            Giải quyết khiếu nại của khách hàng và các khảo sát để chăm sóc khách hàng tốt hơn.
          </span>
        </li>
        <li className='c1 li-bullet-0'>
          <span className='c5'>
            Các trường hợp có sự yêu cầu của cơ quan nhà nước có thẩm quyền, theo đúng quy định của pháp luật.
          </span>
        </li>
      </ul>
      <h1 className='c8' id='h.hoorwtx5kct1'>
        <span className='c4'>3. Thời gian lưu trữ thông tin</span>
      </h1>
      <p className='c2'>
        <span>
          Dữ liệu cá nhân của Thành viên sẽ được lưu trữ cho đến khi có yêu cầu hủy bỏ hoặc tự thành viên đăng nhập và
          thực hiện hủy bỏ. Còn lại trong mọi trường hợp thông tin cá nhân thành viên sẽ được bảo mật trên máy chủ của{' '}
        </span>
        <span className='c6'>{VnLife}</span>
        <span className='c5'>.</span>
      </p>
      <p className='c2 c3'>
        <span className='c5' />
      </p>
      <h1 className='c8' id='h.afvhgnoq55cq'>
        <span className='c4'>4. Phương tiện và công cụ để người dùng tiếp cận và chỉnh sửa dữ liệu cá nhân</span>
      </h1>
      <p className='c2'>
        <span>Người dùng đăng ký tài khoản với </span>
        <span className='c6'>{VnLife} </span>
        <span className='c5'>có thể quản lý thông tin cá nhân bằng các hình thức sau:</span>
      </p>
      <ul className='c11 lst-kix_l6jbj1wrstfp-0'>
        <li className='c1 li-bullet-0'>
          <span>Đăng nhập và truy cập trang quản lý tài khoản cá nhân: </span>
          <span className='c6'>
            <a className='c0' href='https://identity.teko.vn/user'>
              https://identity.teko.vn/user
            </a>
            .
          </span>
        </li>
        <li className='c1 li-bullet-0'>
          <span>Trực tiếp yêu cầu hoặc gửi yêu cầu qua email/gọi điện đến các kênh liên lạc chính thức của </span>
          <span className='c6'>{VnLife}</span>
          <span className='c5'>:</span>
        </li>
      </ul>
      <div style={{ marginLeft: '40px' }}>
        <p className='c2 c7'>
          <span className='c9'>Địa chỉ</span>
          <span className='c5'>: Tầng 11-12, Số 36 Hoàng Cầu, Phường Ô Chợ Dừa, Quận Đống Đa, Hà Nội, Việt Nam</span>
        </p>
        <p className='c2 c7'>
          <span className='c9'>Email</span>
          <span className='c5'>: info@vnlife.vn</span>
        </p>
        <p className='c2 c7'>
          <span className='c9'>Phone</span>
          <span className='c5'>: + 84-24 35 992 992</span>
        </p>
        <p className='c2 c3 c7'>
          <span className='c5' />
        </p>
      </div>
      <h1 className='c8' id='h.wszp48wp1j4t'>
        <span className='c4'>5. Cam kết bảo mật thông tin cá nhân khách hàng</span>
      </h1>
      <p className='c2'>
        <span className='c5'>Khách hàng có quyền yêu cầu cập nhật hoặc xoá dữ liệu cá nhân của mình. </span>
        <span className='c5'>
          Để cập nhật hoặc xoá dữ liệu cá nhân, vui lòng truy cập trang{' '}
          <a className='c0' href='https://identity.teko.vn/user'>
            https://identity.teko.vn/user
          </a>
          , chọn "Thông tin cá nhân" và thao tác trên giao diện.
        </span>
      </p>
      <p className='c2 c3'>
        <span className='c5' />
      </p>
      <p className='c2'>
        <span>Thông tin cá nhân đăng ký tại </span>
        <span className='c6'>{VnLife} </span>
        <span>&nbsp;được </span>
        <span className='c6'>{VnLife}</span>
        <span>&nbsp;</span>
        <span>cam kết bảo mật tuyệt đối theo chính sách bảo vệ thông tin cá nhân của </span>
        <span className='c6'>{VnLife}</span>
        <span className='c5'>
          . Việc thu thập và sử dụng thông tin của mỗi thành viên chỉ được thực hiện khi có sự đồng ý của khách hàng đó
          trừ những trường hợp pháp luật có quy định khác.
        </span>
      </p>
      <p className='c2 c3'>
        <span className='c5' />
      </p>
      <p className='c2'>
        <span>
          Chỉ sử dụng thông tin khách hàng vào các nội dung ở mục 2. Không phát tán, cung cấp thông tin khách hàng cho
          bên thứ 3 trừ trường hợp cung cấp cho công ty tài chính khi khách hàng mua trả góp, cung cấp hãng để trao
          thưởng; cung cấp cho công ty đối tác giao vận để liên lạc khi giao hàng hoặc trường hợp khác khi cơ quan nhà
          nước có thẩm quyền yêu cầu.
          <br />
          Với các đối tác bên thứ 3,{' '}
        </span>
        <span className='c6'>{VnLife}</span>
        <span>
          &nbsp;sẽ đảm bảo ký cam kết bảo mật thông tin khách hàng trước khi sử dụng dịch vụ / chia sẻ thông tin.
        </span>
      </p>
      <p className='c2 c3'>
        <span className='c5' />
      </p>
      <p className='c2'>
        <span>
          Chúng tôi cam kết &nbsp;sử dụng các biện pháp quản lý, kỹ thuật phù hợp để bảo vệ thông tin &nbsp;do mình thu
          thập, lưu trữ; tuân thủ các tiêu chuẩn, quy chuẩn kỹ thuật về bảo đảm an toàn thông tin mạng. Trong trường hợp
          máy chủ lưu trữ thông tin bị hacker tấn công dẫn đến mất mát dữ liệu cá nhân thành viên,
        </span>
        <span className='c6'>{VnLife}</span>
        <span className='c5'>
          &nbsp;sẽ có trách nhiệm thông báo vụ việc cho cơ quan chức năng điều tra xử lý kịp thời và thông báo cho thành
          viên được biết.
        </span>
      </p>
      <p className='c2 c3'>
        <span className='c5' />
      </p>
      <p className='c2'>
        <span className='c5'>
          Bảo mật tuyệt đối mọi thông tin trực tuyến của khách hàng bao gồm lịch sử đăng nhập, cập nhật, thông tin tài
          khoản, các hành vi browsing (duyệt web) khi sử dụng các dịch vụ của VnLife, các giao dịch mua hàng ,thanh
          toán,..
        </span>
      </p>
      <p className='c2 c3'>
        <span className='c5' />
      </p>
      <p className='c2'>
        <span className='c6'>{VnLife}</span>
        <span>
          &nbsp;yêu cầu các cá nhân khi đăng ký thành viên phải cung cấp đầy đủ và chính xác các thông tin cá nhân có
          liên quan như: Họ và tên, địa chỉ liên lạc, email, điện thoại, …., và chịu trách nhiệm về tính pháp lý của
          những thông tin trên.{' '}
        </span>
        <span className='c6'>{VnLife}</span>
        <span className='c5'>
          &nbsp;không chịu trách nhiệm cũng như không giải quyết mọi khiếu nại có liên quan đến quyền lợi của cá nhân đó
          nếu xét thấy các thông tin cá nhân cung cấp khi đăng ký ban đầu là không chính xác
        </span>
      </p>
      <p className='c2 c3'>
        <span className='c5' />
      </p>
    </div>
  );
};

export default Policy;
