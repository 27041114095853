import React, { Suspense } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { i18n, I18nextProvider } from 'i18n';
import { browserHistory } from 'Helper/history';
import AlertLayout from 'Containers/AlertLayout';
import PrivateLayout from 'Containers/PrivateLayout';
import PublicLayout from 'Containers/PublicLayout';
import PrivacyPolicy from 'Screens/PrivacyPolicy';
import 'Customize';

const App = (props) => {
  return (
    <Router history={browserHistory}>
      <I18nextProvider i18n={i18n}>
        <Suspense fallback=''>
          <Switch>
            <Route exact path='/privacy' component={PrivacyPolicy} />
            <Route path='/alert' component={AlertLayout} />
            <Route path='/user' component={PrivateLayout} />
            <Route path='/' component={PublicLayout} />
          </Switch>
        </Suspense>
      </I18nextProvider>
    </Router>
  );
};

export default App;
