import { client, bigIntAuthClient } from 'Services/clients';

const getChallengeAPI = '/api/v2/get-challenge';
const resolveChallengeAPI = '/api/v2/resolve-challenge';

const getChallenge = (request) => client.post(`${getChallengeAPI}`, { ...request });
const getChallengeAuth = (request) => bigIntAuthClient.post(`${getChallengeAPI}`, { ...request });
const resolveChallenge = (request) => client.post(`${resolveChallengeAPI}`, { ...request });
const resolveChallengeAuth = (request) => bigIntAuthClient.post(`${resolveChallengeAPI}`, { ...request });

export const challengeService = {
  getChallenge,
  getChallengeAuth,
  resolveChallenge,
  resolveChallengeAuth
};