import React, { Fragment, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Typography, FormControl, Divider, Button } from '@material-ui/core';
import { Loading } from 'Components';
import { userService } from 'Services/user';
import { redirect } from 'Helper/history';
import { validator } from 'Helper/validate';

const Logout = ({ t, challenge, client }) => {
  const YES = t('Log out');
  const NO = t('Back');

  const [isLoading, setIsLoading] = useState(false);

  const { buttonColor } = useMemo(() => {
    const { buttonColor } = client.theme || {};
    return {
      buttonColor: validator.isValidColor(buttonColor) ? buttonColor : undefined,
    };
  }, [client]);

  const handleClickSubmit = (answer) => {
    const request = {
      challenge,
      confirmed: answer === YES,
    };
    setIsLoading(answer);
    userService
      .logout(request)
      .then((response) => {
        const { redirectTo } = response;
        redirect(redirectTo);
      })
      .catch(() => setIsLoading());
  };

  return (
    <Fragment>
      <Typography variant='h5' className='mb-36'>
        {t('Logout')}
      </Typography>

      <div className='flex-center my-12'>
        <Divider className='flex-1' />
        <span className='mx-12 color-gray text-bolder'>{t('Are you sure you want to log out?')}</span>
        <Divider className='flex-1' />
      </div>

      <div className='mb-24' />
      <FormControl className='mb-12 mx-24'>
        <Button variant='outlined' onClick={() => handleClickSubmit(NO)}>
          <Loading visible={isLoading === NO} /> {NO}
        </Button>
      </FormControl>

      <FormControl className='mb-12 mx-24'>
        <Button
          color='primary'
          style={{ color: buttonColor }}
          variant='contained'
          onClick={() => handleClickSubmit(YES)}
        >
          <Loading visible={isLoading === YES} /> {YES}
        </Button>
      </FormControl>
    </Fragment>
  );
};

export default withTranslation()(Logout);
