import React, { Fragment, useEffect, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { Typography, FormControl, Divider, Button } from '@material-ui/core';
import { validator } from 'Helper/validate';

const SessionExpired = ({ t, client }) => {
  const buttonColor = useMemo(() => {
    const buttonColor = client && client.theme && client.theme.buttonColor;
    return validator.isValidColor(buttonColor) ? buttonColor : undefined;
  }, [client]);

  const handleClickSubmit = () => {
    if (window.TekoID.user) window.TekoID.user.login();
  };

  useEffect(() => {
    if (window.TekoID.user) window.TekoID.user.unloadUser();
  }, []);

  return (
    <Fragment>
      <div className='flex-1 mx-12'>
        <div className='justify-content-center'>
          <div className='text-align-center' style={{ flex: 1, maxWidth: 420 }}>
            <Typography variant='h6' className='mb-36' style={{ color: '#f74949' }}>
              {t('Session Expired')}
            </Typography>

            <div className='flex-center my-12'>
              <Divider className='flex-1' />
              <span className='mx-12 color-gray text-bolder'>{t('Please login again')}</span>
              <Divider className='flex-1' />
            </div>

            <div className='mb-24' />
            <FormControl fullWidth className='mb-12'>
              <Button
                color='primary'
                style={{ color: buttonColor }}
                variant='contained'
                onClick={() => handleClickSubmit()}
              >
                {t('Login')}
              </Button>
            </FormControl>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withTranslation()(SessionExpired);
