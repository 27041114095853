import React, { Fragment, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { Typography, FormControl, Divider, Button } from '@material-ui/core';
import { browserHistory } from 'Helper/history';
import { validator } from 'Helper/validate';

const Error = ({ t, client }) => {
  const handleClickSubmit = () => {
    browserHistory.goBack();
  };

  const { state } = browserHistory.location;

  const buttonColor = useMemo(() => {
    const buttonColor = client && client.theme && client.theme.buttonColor;
    return validator.isValidColor(buttonColor) ? buttonColor : undefined;
  }, [client]);

  return (
    <Fragment>
      <Typography variant='h6' className='mb-36' style={{ color: '#f74949' }}>
        {state === 500 && 'Oops... Internal Server Error'}
        {state === 404 && 'Oops... Page Not Found'}
        {state === 403 && 'Oops... Forbidden'}
        {state === 400 && 'Oops... Bad Request'}
      </Typography>

      <div className='flex-center my-12'>
        <Divider className='flex-1' />
        <span className='mx-12 color-gray text-bolder'>{t('Your request is not valid')}</span>
        <Divider className='flex-1' />
      </div>

      <div className='mb-24' />
      <FormControl fullWidth className='mb-12'>
        <Button color='primary' style={{ color: buttonColor }} variant='contained' onClick={() => handleClickSubmit()}>
          {t('Back')}
        </Button>
      </FormControl>
    </Fragment>
  );
};

export default withTranslation()(Error);
