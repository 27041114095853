import React, { Fragment, useState, useEffect, useMemo, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { withPermission } from 'Containers/ScreenPermission';
import { RecaptchaLayout } from 'Containers/RecaptchaLayout';
import { compose } from 'recompose';
import { CLIENT_PERMISSIONS as P } from 'Containers/PublicLayout';
import { Typography, FormControl, TextField, Divider, Button, InputAdornment } from '@material-ui/core';
import { Alert, Loading, ColorLink } from 'Components';
import { AccountCircleIcon, ArrowBackIcon } from 'Components/Icons';
import { userService } from 'Services/user';
import { browserHistory, redirect } from 'Helper/history';
import { validator } from 'Helper/validate';
import { t as tr } from 'Helper/translator';
import { PublicRouter } from 'Navigation/Router';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const Activation = ({ t, challenge, client }) => {
  const [username, setUsername] = useState('');
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCode, setIsLoadingCode] = useState(false);
  const [counter, setCounter] = useState(0);

  const { buttonColor } = useMemo(() => {
    const { buttonColor } = client.theme || {};
    return { buttonColor: validator.isValidColor(buttonColor) ? buttonColor : undefined };
  }, [client]);

  const isValidate = () => {
    let isError = false;
    if (!validator.isValidCode(code)) {
      setCodeError(true);
      isError = true;
    }
    return !isError;
  };

  const handleClickSubmit = () => {
    if (isValidate()) {
      const request = {
        username,
        code,
        challenge
      };
      setIsLoading(true);
      userService
        .confirmUser(request)
        .then((response) => {
          Alert.success(t('Your account is now active'));
          browserHistory.push({
            pathname: PublicRouter.Login.url,
            search: `challenge=${challenge}`,
          });
          const { redirectTo } = response;
          if (redirectTo)
            redirect(redirectTo);
        })
        .catch(() => {})
        .then(() => setIsLoading(false));
    }
  };

  const handlePressKey = (event) => {
    if (event.key === 'Enter') {
      handleClickSubmit();
    }
  };

  const recaptchaEnabled = (typeof client.recaptchaConfiguration === 'object' && client.recaptchaConfiguration) ? client.recaptchaConfiguration.enabled : false;
  const { executeRecaptcha } = useGoogleReCaptcha();
  const resendActivationCode = useCallback(async () => {
    if (counter > 0) return;

    if (recaptchaEnabled && !executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const recaptchaToken = recaptchaEnabled ? await executeRecaptcha('getCaptchaToken') : '';
    const request = { username, recaptchaToken };
    setIsLoadingCode(true);
    userService
      .resendActivationCode(request)
      .then((response) => {
        const message = validator.isEmail({ username }) ? t('Please check your email') : t('Please check your phone');
        Alert.success(message);

        setCounter(60);
      })
      .catch(() => {})
      .then(() => setIsLoadingCode(false));
  }, [executeRecaptcha, username]);

  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => {
        setCounter((counter) => counter - 1);
      }, 1000);
    }
  }, [counter]);

  const { state = {} } = browserHistory.location;

  useEffect(() => {
    if (state.username) {
      const isEmail = validator.isEmail(state);
      const message = isEmail ? tr('Please check your email') : tr('Please check your phone');
      Alert.success(message);
      setUsername(state.username);
    } else {
      browserHistory.replace({ pathname: PublicRouter.Error.url, state: 500 });
    }
  }, [state]);

  return (
    <Fragment>
      <Typography variant='h5' className='mb-36'>
        {t('Account verification')}
      </Typography>
      <div className='flex-center my-12'>
        <Divider className='flex-1' />
        <span className='mx-12 color-gray text-bolder'>{t('Enter your activation code')}</span>
        <Divider className='flex-1' />
      </div>
      <FormControl fullWidth className='mb-12'>
        <TextField
          label={t('Account')}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position='end'>
                <AccountCircleIcon />
              </InputAdornment>
            ),
          }}
          margin='dense'
          variant='outlined'
          value={state.username}
        />
      </FormControl>
      <FormControl fullWidth className='mb-12'>
        <TextField
          autoFocus
          label={t('Activation code')}
          margin='dense'
          variant='outlined'
          value={code}
          error={codeError}
          onChange={(event) => {
            const { value } = event.target;
            setCode(value);
            setCodeError(!validator.isValidCode(value));
          }}
          onKeyPress={(e) => handlePressKey(e)}
        />
      </FormControl>
      <FormControl fullWidth>
        <Typography component='div' variant='body2' className='justify-content-center'>
          <ColorLink className='align-items-center' disabled={counter > 0} onClick={() => resendActivationCode()}>
            <Loading visible={isLoadingCode} />
            {counter > 0 && <span style={{ marginRight: 8 }}>({counter})</span>}
            {t('Resend activation code')}
          </ColorLink>
        </Typography>
      </FormControl>
      <div className='mb-24' />
      <FormControl fullWidth className='mb-24'>
        <Button color='primary' style={{ color: buttonColor }} variant='contained' onClick={() => handleClickSubmit()}>
          <Loading visible={isLoading} /> {t('Verify account')}
        </Button>
      </FormControl>
      <FormControl fullWidth className='mb-12'>
        <Typography variant='body1' className='justify-content-end'>
          <ColorLink
            to={{
              pathname: PublicRouter.Login.url,
              search: `challenge=${challenge}`,
            }}
          >
            {t('Login')}
          </ColorLink>
        </Typography>
      </FormControl>
      <div style={{ position: 'absolute', top: 32, cursor: 'pointer' }} title={t('Back')}>
        <ColorLink
          onClick={() => {
            browserHistory.goBack();
          }}
        >
          <ArrowBackIcon />
        </ColorLink>
      </div>
    </Fragment>
  );
};

export default compose(withPermission([P.USERS_EMAIL_REGISTER,P.USERS_PHONE_NUMBER_REGISTER]), withTranslation(), RecaptchaLayout())(Activation);
