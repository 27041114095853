import React from 'react';
import { connect } from 'react-redux';
import { Avatar as AntAvatar } from 'antd';
import { Tooltip } from '@material-ui/core';
import { AccountCircleIcon } from 'Components/Icons';

const Avatar = ({ profile, size, title = '' }) => {
  return (
    <Tooltip title={title} placement='right-end'>
      {profile.avatarUrl ? (
        <AntAvatar size={size} src={profile.avatarUrl} />
      ) : (
        <div
          style={{ width: size, height: size, borderRadius: '50%', border: '1px solid silver' }}
          className='bg-warning justify-content-center align-items-center'>
          <AccountCircleIcon style={{ fontSize: size }} />
        </div>
      )}
    </Tooltip>
  );
};

const mapStateToProps = (state) => ({
  profile: state.user,
});

export default connect(mapStateToProps)(Avatar);
