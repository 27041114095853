const base64StrToArrayBuffer = (base64Str) => {
  // base64url to base64
  base64Str = base64Str.replace(/-/g, '+').replace(/_/g, '/');

  // base64 to Uint8Array
  const str = window.atob(base64Str);
  const bytes = new Uint8Array(str.length);
  for (let i = 0; i < str.length; i++) {
    bytes[i] = str.charCodeAt(i);
  }
  return bytes;
};

const arrayBufferToBase64Url = (array) => {
  // Array or ArrayBuffer to Uint8Array
  if (Array.isArray(array)) {
    array = Uint8Array.from(array);
  }

  if (array instanceof ArrayBuffer) {
    array = new Uint8Array(array);
  }

  // Uint8Array to base64
  let base64 = '';
  for (let i = 0; i < array.byteLength; i++) {
    base64 += String.fromCharCode(array[i]);
  }
  base64 = window.btoa(base64);
  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=*$/g, '');
};

const createCredential = (creationOptions) => {
  let copiedCreationOptions = JSON.parse(JSON.stringify(creationOptions));

  copiedCreationOptions.challenge = base64StrToArrayBuffer(copiedCreationOptions.challenge);
  copiedCreationOptions.user.id = base64StrToArrayBuffer(copiedCreationOptions.user.id);
  for (let i = 0; i < copiedCreationOptions.excludeCredentials.length; i++) {
    let excludeCredential = copiedCreationOptions.excludeCredentials[i];
    copiedCreationOptions.excludeCredentials[i].id = base64StrToArrayBuffer(excludeCredential.id);
  }
  return navigator.credentials.create({
    publicKey: copiedCreationOptions,
  });
};

const buildRegistrationRequest = (credential) => {
  let attestationObject = credential.response.attestationObject;
  let clientDataJSON = credential.response.clientDataJSON;
  let rawId = credential.rawId;
  return {
    id: credential.id,
    rawId: arrayBufferToBase64Url(rawId),
    type: credential.type,
    response: {
      attestationObject: arrayBufferToBase64Url(attestationObject),
      clientDataJson: arrayBufferToBase64Url(clientDataJSON),
    },
  };
};

const getCredential = (credentialRequestOptions) => {
  let copiedOptions = JSON.parse(JSON.stringify(credentialRequestOptions));

  copiedOptions.challenge = base64StrToArrayBuffer(copiedOptions.challenge);
  for (let i = 0; i < copiedOptions.allowCredentials.length; i++) {
    let allowCredential = copiedOptions.allowCredentials[i];
    copiedOptions.allowCredentials[i].id = base64StrToArrayBuffer(allowCredential.id);
  }
  return navigator.credentials.get({
    publicKey: copiedOptions,
  });
};

const buildAuthenticationRequest = (credential) => {
  let authData = credential.response.authenticatorData;
  let clientDataJSON = credential.response.clientDataJSON;
  let rawId = credential.rawId;
  let sig = credential.response.signature;
  let userHandle = credential.response.userHandle;
  return {
    id: credential.id,
    rawId: arrayBufferToBase64Url(rawId),
    type: credential.type,
    response: {
      authenticatorData: arrayBufferToBase64Url(authData),
      clientDataJson: arrayBufferToBase64Url(clientDataJSON),
      signature: arrayBufferToBase64Url(sig),
      userHandle: arrayBufferToBase64Url(userHandle),
    },
  };
};

export const webauthnUtil = {
  base64StrToArrayBuffer,
  arrayBufferToBase64Url,
  createCredential,
  buildRegistrationRequest,
  getCredential,
  buildAuthenticationRequest,
};
