const {
  REACT_APP_ENV_MODE,
  REACT_APP_API_URL,
  REACT_APP_CLIENT_ID,
  REACT_APP_REDIRECT_URI,
  REACT_APP_REDIRECT_URI_DEFAULT,
  REACT_APP_ACCEPTED_REDIRECT_URIS,
  REACT_APP_AUTH_URL,
  REACT_APP_SCOPES,

  REACT_APP_GOOGLE_CLIENT_ID,
  REACT_APP_FACEBOOK_CLIENT_ID,

  OAUTH_CLIENT_ID,
  OAUTH_DOMAIN,
  POLICY_URL,
  CUSTOM_REGISTER_POLICY_CHECKBOX
} = window;

export default {
  REACT_APP_ENV_MODE,
  REACT_APP_API_URL,
  REACT_APP_CLIENT_ID: OAUTH_CLIENT_ID || REACT_APP_CLIENT_ID,
  REACT_APP_REDIRECT_URI: (REACT_APP_ACCEPTED_REDIRECT_URIS && REACT_APP_ACCEPTED_REDIRECT_URIS.includes(window.location.href)) ?
      window.location.href : (REACT_APP_REDIRECT_URI_DEFAULT && REACT_APP_REDIRECT_URI),
  REACT_APP_AUTH_URL: OAUTH_DOMAIN || REACT_APP_AUTH_URL,
  REACT_APP_SCOPES,

  REACT_APP_GOOGLE_CLIENT_ID,
  REACT_APP_FACEBOOK_CLIENT_ID,
  POLICY_URL,
  CUSTOM_REGISTER_POLICY_CHECKBOX
};
