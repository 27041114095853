import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import { UserAvatar } from 'Components';

const Dashboard = ({ profile }) => {
  return (
    <Fragment>
      <div className='flex-1 mx-12'>
        <div className='text-align-center'>
          <div className='user-avatar mb-12'>
            <UserAvatar size={128} />
          </div>
          <Typography variant='h5' className='mb-12'>
            {profile.name}
          </Typography>
          <Typography variant='body1' color='textSecondary' className='mb-12'>
            {profile.email || profile.phoneNumber}
          </Typography>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  profile: state.user,
});

export default connect(mapStateToProps)(Dashboard);
