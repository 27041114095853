import { userService } from 'Services/user';
import {webauthnService} from "../../Services/webauthn";

export const ActionType = {
  GET_ME: 'GET_ME',
  GET_CLIENT: 'GET_CLIENT',
  GET_WEBAUTHN_CREDENTIAL_LIST: 'GET_WEBAUTHN_CREDENTIAL_LIST',
  GET_OIDC_CONTEXT: 'GET_OIDC_CONTEXT'
};

const updateMe = (userInfo) => (dispatch) => {
  dispatch({
    type: ActionType.GET_ME,
    data: userInfo,
  });
};

const fetchMe = () => (dispatch) => {
  userService.getUserInfo().then((userInfo) => {
    dispatch({
      type: ActionType.GET_ME,
      data: userInfo,
    });
  });
};

const updateClient = (client) => (dispatch) => {
  dispatch({
    type: ActionType.GET_CLIENT,
    data: client,
  });
};


const updateOIDCContext = (context) => (dispatch) => {
  dispatch({
    type: ActionType.GET_OIDC_CONTEXT,
    data: context,
  });
};

const fetchWebAuthnCredentials = () => (dispatch) => {
  webauthnService.getListWebAuthnCredentials().then((res) => {
    const { items } = res;
    dispatch({
      type: ActionType.GET_WEBAUTHN_CREDENTIAL_LIST,
      data: items,
    });
  });
}

export const ActionSend = {
  updateOIDCContext,
  updateMe,
  fetchMe,
  updateClient,
  fetchWebAuthnCredentials,
};
