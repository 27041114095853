import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ActionSend } from 'Redux/actions';
import { TextField, IconButton, RadioGroup, Radio, FormControl, FormControlLabel } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Icon } from 'antd';
import { Alert } from 'Components';
import { SaveOutlinedIcon } from 'Components/Icons';
import { validator } from 'Helper/validate';
import { userService } from 'Services/user';
import moment from 'moment';

const CollapseItem = ({ t, updateMe, ...props }) => {
  const { init, field, onUpdate } = props;

  const [value, setValue] = useState(init);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const validate = (value) => {
    if (field === 'name') {
      return validator.isEmpty(value);
    }
  };

  const handleClickSave = () => {
    const nextError = validate(value);
    setError(nextError);
    if (error || nextError) return;

    const request = {};
    if (field === 'birthday') {
      request[field] = value.unix();
    } else {
      request[field] = value;
    }

    setIsLoading(true);
    userService
      .updateUserInfo(request)
      .then((response) => {
        updateMe(response);
        onUpdate();

        Alert.success(t('Update information successfully'));
      })
      .catch(() => {})
      .then(() => {
        setIsLoading(false);
      });
  };

  const handlePressKey = (event) => {
    if (event.key === 'Enter') {
      handleClickSave();
    }
  };

  return (
    <div className='flex-row'>
      <div className='form-item-update'>
        {field === 'name' && (
          <TextField
            value={value}
            error={error}
            onChange={(event) => {
              const { value } = event.target;
              setValue(value);
              setError(validate(value));
            }}
            onKeyPress={(e) => handlePressKey(e)}
            style={{ width: 240 }}
          />
        )}
        {field === 'birthday' && (
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              clearable
              placeholder='DD/MM/YYYY'
              format='DD/MM/YYYY'
              value={value}
              onChange={(item) => setValue(moment(item))}
              style={{ width: 180 }}
            />
          </MuiPickersUtilsProvider>
        )}
        {field === 'gender' && (
          <FormControl>
            <RadioGroup value={value} onChange={(event) => setValue(event.target.value)}>
              <FormControlLabel value='Male' control={<Radio color='primary' />} label={t('Male')} />
              <FormControlLabel value='Female' control={<Radio color='secondary' />} label={t('Female')} />
              <FormControlLabel value='Other' control={<Radio color='default' />} label={t('Other')} />
            </RadioGroup>
          </FormControl>
        )}
      </div>
      <IconButton
        color='primary'
        style={{ padding: 8, marginLeft: 12, width: 40, height: 40 }}
        onClick={() => handleClickSave()}>
        {isLoading ? <Icon type='loading' /> : <SaveOutlinedIcon />}
      </IconButton>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateMe: ActionSend.updateMe,
    },
    dispatch,
  );

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(CollapseItem);
