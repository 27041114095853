import React, { Fragment, useState, useEffect, useMemo, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { withPermission } from 'Containers/ScreenPermission';
import { RecaptchaLayout } from 'Containers/RecaptchaLayout';
import { compose } from 'recompose';
import { CLIENT_PERMISSIONS as P } from 'Containers/PublicLayout';
import { Typography, FormControl, TextField, Divider, InputAdornment, Button } from '@material-ui/core';
import { Alert, Loading, ColorLink } from 'Components';
import { DialpadIcon, ArrowBackIcon, EditIcon } from 'Components/Icons';
import { passwordlessService } from 'Services/passwordless';
import { browserHistory, redirect } from 'Helper/history';
import { validator } from 'Helper/validate';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const LoginPasswordless = ({ t, challenge, client }) => {
  const [isCode, setIsCode] = useState(false);
  const [account, setAccount] = useState('');
  const [accountError, setAccountError] = useState(false);
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCode, setIsLoadingCode] = useState(false);
  const [counter, setCounter] = useState(0);

  const { buttonColor } = useMemo(() => {
    const { buttonColor } = client.theme || {};
    return {
      buttonColor: validator.isValidColor(buttonColor) ? buttonColor : undefined,
    };
  }, [client]);

  const isValidate = () => {
    let isError = false;
    if (!validator.isValidPhone(account)) {
      setAccountError(true);
      isError = true;
    }
    if (isCode) {
      if (!validator.isValidCode(code)) {
        setCodeError(true);
        isError = true;
      }
    }
    return !isError;
  };

  const recaptchaEnabled =
    typeof client.recaptchaConfiguration === 'object' && client.recaptchaConfiguration
      ? client.recaptchaConfiguration.enabled
      : false;
  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleClickSubmit = useCallback(async () => {
    if (recaptchaEnabled && !executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const recaptchaToken = recaptchaEnabled ? await executeRecaptcha('getCaptchaToken') : '';
    if (isValidate()) {
      const request = {
        challenge,
        phoneNumber: account,
        code,
        recaptchaToken,
      };
      setIsLoading(true);
      if (!isCode) {
        passwordlessService
          .sendCode(request)
          .then(() => {
            setCode('');
            setIsCode(true);
            Alert.success(t('Please check your phone'));
          })
          .catch(() => {})
          .then(() => setIsLoading(false));
      } else {
        passwordlessService
          .login(request)
          .then((response) => {
            const { redirectTo } = response;
            redirect(redirectTo);
          })
          .catch(() => {})
          .then(() => setIsLoading(false));
      }
    }
  }, [recaptchaEnabled, executeRecaptcha, isValidate, challenge, account, code, isCode, t]);

  const handlePressKey = (event) => {
    if (event.key === 'Enter') {
      handleClickSubmit();
    }
  };

  const resendVerificationCode = useCallback(async () => {
    if (counter > 0) return;

    if (recaptchaEnabled && !executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const recaptchaToken = recaptchaEnabled ? await executeRecaptcha('getCaptchaToken') : '';

    const request = {
      phoneNumber: account,
      recaptchaToken,
    };
    setIsLoadingCode(true);
    passwordlessService
      .sendCode(request)
      .then(() => {
        setIsCode(true);
        Alert.success(t('Please check your phone'));

        setCounter(60);
      })
      .catch(() => {})
      .then(() => setIsLoadingCode(false));
  }, [counter, recaptchaEnabled, executeRecaptcha, account, t]);

  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => {
        setCounter((counter) => counter - 1);
      }, 1000);
    }
  }, [counter]);

  return (
    <Fragment>
      <Typography variant='h5' className='mb-36'>
        {t('Login')}
      </Typography>
      <div className='flex-center my-12'>
        <Divider className='flex-1' />
        <span className='mx-12 color-gray text-bolder'>
          {isCode ? t('Enter the verification code') : t('Enter your phone number')}
        </span>
        <Divider className='flex-1' />
      </div>
      <FormControl fullWidth className='mb-12'>
        <TextField
          label={t('Phone number')}
          InputProps={{
            readOnly: isCode,
            endAdornment: (
              <InputAdornment position='end'>
                {isCode ? (
                  <EditIcon
                    className='cursor-pointer'
                    style={{ color: '#123499' }}
                    onClick={() => {
                      setIsCode(false);
                      setCounter(0);
                    }}
                  />
                ) : (
                  <DialpadIcon style={{ color: '#123499' }} />
                )}
              </InputAdornment>
            ),
          }}
          margin='dense'
          variant='outlined'
          value={account}
          error={accountError}
          onChange={(event) => {
            const { value } = event.target;
            setAccount(value);
            setAccountError(!validator.isValidPhone(value));
          }}
          onKeyPress={(e) => handlePressKey(e)}
        />
      </FormControl>
      {isCode && (
        <FormControl fullWidth className='mb-12'>
          <TextField
            autoFocus
            label={t('Verification code')}
            margin='dense'
            variant='outlined'
            value={code}
            error={codeError}
            onChange={(event) => {
              const { value } = event.target;
              setCode(value);
              setCodeError(!validator.isValidCode(value));
            }}
            onKeyPress={(e) => handlePressKey(e)}
          />
        </FormControl>
      )}
      {isCode && (
        <FormControl fullWidth>
          <Typography component='div' variant='body2' className='justify-content-center'>
            <ColorLink className='align-items-center' disabled={counter > 0} onClick={() => resendVerificationCode()}>
              <Loading visible={isLoadingCode} />
              {counter > 0 && <span style={{ marginRight: 8 }}>({counter})</span>}
              {t('Resend verification code')}
            </ColorLink>
          </Typography>
        </FormControl>
      )}
      <div className='mb-24' />
      <FormControl fullWidth className='mb-12'>
        <Button color='primary' style={{ color: buttonColor }} variant='contained' onClick={() => handleClickSubmit()}>
          <Loading visible={isLoading} /> {isCode ? t('Login') : t('Send verification code')}
        </Button>
      </FormControl>
      <div style={{ position: 'absolute', top: 32, cursor: 'pointer' }} title={t('Back')}>
        <ColorLink
          onClick={() => {
            browserHistory.goBack();
          }}>
          <ArrowBackIcon />
        </ColorLink>
      </div>
    </Fragment>
  );
};

export default compose(withPermission([P.PASSWORDLESS_LOGIN]), withTranslation(), RecaptchaLayout())(LoginPasswordless);
