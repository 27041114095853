import { client } from './clients';
import { PublicRouter } from 'Navigation/Router';

const requestAPI = '/api/v1/requests';

const getAuthentication = ({ challenge, pathname }) => {
  const isLogout = pathname === PublicRouter.Logout.url;
  const pathmatch = isLogout ? PublicRouter.Logout.url : PublicRouter.Login.url;
  return client.get(`${requestAPI}${pathmatch}/${challenge}`);
};

const logout = () => {
  window.TekoID.user.logout();
};

const getUserInfo = () => {
  if (window.TekoID.user.isLoggedIn()) {
    let info;
    try {
      info = window.TekoID.user.getUserInfo();
    } catch (e) {
      info = {};
    }
    return info;
  }
  return {
    name: ' ',
    email: ' ',
    avatarUrl: 'https://i.udemycdn.com/course/750x422/2151662_32fc_4.jpg',
  };
};

export const authService = {
  getAuthentication,

  logout,
  getUserInfo,
};
