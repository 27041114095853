import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { FormControl, TextField, Typography } from '@material-ui/core';
import { ColorLink, Loading, Alert } from 'Components';
import { CloseIcon } from 'Components/Icons';
import { mfaService } from 'Services/mfa';
import { validator } from 'Helper/validate';
import { storePassword } from 'ScreensPrivate/Authenticator';

import SmsIcon from 'Assets/images/iconfinder_14_4698578.png';

const DialogSms = ({ t, profile, ...props }) => {
  const { onCancel, onSuccess } = props;

  const [isCode, setIsCode] = useState(false);
  const [isLoadingSend, setIsLoadingSend] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [counter, setCounter] = useState(0);

  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState(false);

  const isValidate = () => {
    let isError = false;
    if (!validator.isValidCode(code)) {
      setCodeError(true);
      isError = true;
    }
    return !isError;
  };

  const handleClickSend = () => {
    if (counter > 0) return;

    setIsLoadingSend(true);
    mfaService
      .associate({
        password: storePassword(),
        authenticatorType: 'sms',
      })
      .then(() => {
        setIsCode(true);
        Alert.success(t('Please check your phone'));
        
        setCounter(60);
      })
      .catch(() => {})
      .then(() => setIsLoadingSend(false));
  };

  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => {
        setCounter((counter) => counter - 1);
      }, 1000);
    }
  }, [counter]);

  const handleClickSubmit = () => {
    if (isValidate()) {
      setIsLoadingSubmit(true);
      mfaService
        .confirm({
          password: storePassword(),
          authenticatorType: 'sms',
          code,
        })
        .then(() => {
          Alert.success(t('Active 2-Step Verification successfully'));

          onSuccess();
          onCancel();
        })
        .catch(() => {})
        .then(() => setIsLoadingSubmit(false));
    }
  };

  const handleClickCancel = () => {
    onCancel();
  };

  return (
    <div className='Dialog-small-container'>
      <div
        className='flex-center'
        style={{ backgroundColor: '#4285f4', height: '160px', margin: '-18px -18px 12px -18px' }}>
        <img width={132} height={132} src={SmsIcon} alt='' />
      </div>

      <div className='mb-24'>
        <Typography variant='subtitle1'>{t('Set up your phone')}</Typography>
        <Typography variant='body2' color='textSecondary'>
          {t('Verification code will be sent to your phone')}.
        </Typography>
      </div>

      <FormControl className='mb-12'>
        <TextField
          label={t('Phone number')}
          InputProps={{
            readOnly: true,
          }}
          value={profile.phoneNumber}
        />
      </FormControl>
      <br />

      {isCode && (
        <FormControl className='mb-12'>
          <TextField
            autoFocus
            label={t('Code')}
            value={code}
            error={codeError}
            onChange={(event) => {
              const { value } = event.target;
              setCode(value);
              setCodeError(!validator.isValidCode(value));
            }}
          />
        </FormControl>
      )}
      <br />
      <Typography component='div' variant='body2' className='flex-inline'>
        <ColorLink className='align-items-center' disabled={counter > 0} onClick={() => handleClickSend()}>
          <Loading visible={isLoadingSend} />
          {counter > 0 && <span style={{ marginRight: 8 }}>({counter})</span>}
          {isCode ? t('Resend verification code') : t('Send verification code')}
        </ColorLink>
      </Typography>
      <div className='mb-48' />

      <div className='mb-12'>
        <Typography component='div' variant='button' className='justify-content-end'>
          <ColorLink onClick={() => handleClickCancel()}>{t('Cancel')}</ColorLink>
          {isCode && (
            <>
              <span style={{ width: 24 }}></span>
              <ColorLink className='align-items-center' onClick={() => handleClickSubmit()}>
                <Loading visible={isLoadingSubmit} />
                {t('Confirm')}
              </ColorLink>
            </>
          )}
        </Typography>
      </div>

      <div style={{ position: 'absolute', top: 18, right: 18, cursor: 'pointer' }} title={t('Close')}>
        <ColorLink onClick={() => handleClickCancel()}>
          <CloseIcon style={{ color: '#fffc' }} />
        </ColorLink>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  profile: state.user,
});

export default compose(withTranslation(), connect(mapStateToProps))(DialogSms);
