import { ActionType } from 'Redux/actions';

export default (state = {}, action) => {
  switch (action.type) {
    case ActionType.GET_OIDC_CONTEXT:
      return { ...action.data };
    default:
      return { ...state };
  }
};
