import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Typography, Hidden, Menu, IconButton, Button, Divider } from '@material-ui/core';
import { UserAvatar, AppMenu, LanguageSwitcher } from 'Components';
import { MenuIcon } from 'Components/Icons';
import { authService } from 'Services/auth';

const Header = ({ t, profile }) => {
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [anchorUser, setAnchorUser] = useState(null);

  const handleClickMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorMenu(null);
  };

  const handleClickUser = (event) => {
    setAnchorUser(event.currentTarget);
  };

  const handleCloseUser = () => {
    setAnchorUser(null);
  };

  return (
    <div className='form-header'>
      <div className='justify-content-between flex-1 px-24'>
        <div className='align-items-center'>
          <Hidden mdUp>
            <IconButton onClick={handleClickMenu}>
              <MenuIcon />
            </IconButton>
            <Menu
              className='app-menu-container'
              anchorEl={anchorMenu}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={Boolean(anchorMenu)}
              onClose={handleCloseMenu}>
              <AppMenu onClickMenu={handleCloseMenu} />
            </Menu>
          </Hidden>
          <LanguageSwitcher />
        </div>
        <div className='align-items-center justify-content-end'>
          <Hidden xsDown>
            <Typography variant='body1' className='mr-12'>
              {t('Hi')} <span className='name-gretting'>{profile.name}</span>
            </Typography>
          </Hidden>

          <IconButton onClick={handleClickUser}>
            <UserAvatar size={24} />
          </IconButton>
          <Menu
            className='user-menu-container'
            anchorEl={anchorUser}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={Boolean(anchorUser)}
            onClose={handleCloseUser}>
            <div className='user-menu'>
              <div className='user-menu-item'>
                <div className='user-avatar mb-12'>
                  <UserAvatar size={80} />
                </div>
                <Typography variant='body1'>{profile.name}</Typography>
                <Typography variant='body2' color='textSecondary'>
                  {profile.email || profile.phoneNumber}
                </Typography>
              </div>
              <Divider />
              <div className='user-menu-item'>
                <Button
                  variant='outlined'
                  onClick={() => {
                    handleCloseUser();
                    authService.logout();
                  }}>
                  {t('Log out')}
                </Button>
              </div>
            </div>
          </Menu>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  profile: state.user,
});

export default compose(withTranslation(), connect(mapStateToProps))(Header);
