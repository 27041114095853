import React, { Fragment } from 'react';
import { Typography } from '@material-ui/core';

const logo = '/assets/logo.png';

const Logo = ({ client = {} }) => {
  const { clientName = '', logoUri = '' } = client;

  return (
    <Fragment>
      <img
        src={logoUri || logo}
        alt={clientName}
        className={`${logoUri ? 'app-logo' : 'app-logo-teko'} mb-12`}
        style={{ maxWidth: '100%' }}
      />
      <Typography variant='subtitle2' className='color-gray mb-12'>
        {logoUri ? '' : ''}
      </Typography>
    </Fragment>
  );
};

export default Logo;
