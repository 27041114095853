import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { FormControl, TextField, Typography, Link } from '@material-ui/core';
import { ColorLink, Loading, Alert } from 'Components';
import { CloseIcon } from 'Components/Icons';
import { mfaService } from 'Services/mfa';
import { validator } from 'Helper/validate';
import { storePassword } from 'ScreensPrivate/Authenticator';

import { staticGoogleImageUrl } from './Greeting';

const DialogAuthen = ({ t, profile, ...props }) => {
  const { onCancel, onSuccess } = props;

  const [qrSource, setQrSource] = useState('');
  const [isQrcode, setIsQrcode] = useState(true);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const handleClickNext = () => setIsQrcode(false);
  const handleClickBack = () => setIsQrcode(true);

  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState(false);

  const isValidate = () => {
    let isError = false;
    if (!validator.isValidCode(code)) {
      setCodeError(true);
      isError = true;
    }
    return !isError;
  };

  const handleClickSubmit = () => {
    if (isValidate()) {
      setIsLoadingSubmit(true);
      mfaService
        .confirm({
          password: storePassword(),
          authenticatorType: 'totp',
          code,
        })
        .then(() => {
          Alert.success(t('Active 2-Step Verification successfully'));

          onSuccess();
          onCancel();
        })
        .catch(() => {})
        .then(() => setIsLoadingSubmit(false));
    }
  };

  const handleClickCancel = () => {
    onCancel();
  };

  useEffect(() => {
    mfaService
      .associate({
        password: storePassword(),
        authenticatorType: 'totp',
      })
      .then((response) => {
        const { success, qrcode } = response;
        if (success) {
          setQrSource(qrcode);
        }
      });
  }, []);

  return (
    <div className='Dialog-small-container'>
      <div
        className='flex-center'
        style={{ backgroundColor: '#4285f4', height: '160px', margin: '-18px -18px 12px -18px' }}>
        <div
          style={{
            background: `no-repeat url(${staticGoogleImageUrl}) 0 -1202px`,
            backgroundSize: '304px 3143px',
            width: '105px',
            height: '105px',
          }}
        />
      </div>

      <div className='mb-24'>
        <Typography variant='subtitle1'>{t('Set up Authenticator')}</Typography>
        {isQrcode ? (
          <>
            <Typography variant='body2' color='textSecondary'>
              {t('Get Google Authenticator from the')}{' '}
              <Link
                href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
                target='_blank'>
                Play Store
              </Link>
              {' ' + t('or') + ' '}
              <Link href='https://apps.apple.com/us/app/google-authenticator/id388497605' target='_blank'>
                App Store
              </Link>
              {'.'}
            </Typography>
            <Typography variant='body2' color='textSecondary'>
              {t('Process')} <span style={{ fontWeight: 500 }}>{t('Scan a barcode')}</span>.
            </Typography>
          </>
        ) : (
          <Typography variant='body2' color='textSecondary'>
            {t('Enter the 6-digit code you see in the application')}.
          </Typography>
        )}
      </div>

      {isQrcode ? (
        <div className='justify-content-center'>
          {qrSource ? (
            <img style={styleImage} src={qrSource} alt='' />
          ) : (
            <span style={{ ...styleImage, paddingLeft: 10 }} className='flex-center'>
              <Loading visible />
            </span>
          )}
        </div>
      ) : (
        <FormControl>
          <TextField
            autoFocus
            label={t('Code')}
            value={code}
            error={codeError}
            onChange={(event) => {
              const { value } = event.target;
              setCode(value);
              setCodeError(!validator.isValidCode(value));
            }}
          />
        </FormControl>
      )}
      <div className='mb-48' />

      <div className='mb-12'>
        <Typography component='div' variant='button' className='justify-content-end'>
          {isQrcode ? (
            <>
              <ColorLink onClick={() => handleClickCancel()}>{t('Cancel')}</ColorLink>
              <span style={{ width: 24 }}></span>
              <ColorLink onClick={() => handleClickNext()}>{t('Next')}</ColorLink>
            </>
          ) : (
            <>
              <ColorLink onClick={() => handleClickBack()}>{t('Back')}</ColorLink>
              <span style={{ width: 24 }}></span>
              <ColorLink className='align-items-center' onClick={() => handleClickSubmit()}>
                <Loading visible={isLoadingSubmit} />
                {t('Verify')}
              </ColorLink>
            </>
          )}
        </Typography>
      </div>

      <div style={{ position: 'absolute', top: 18, right: 18, cursor: 'pointer' }} title={t('Close')}>
        <ColorLink onClick={() => handleClickCancel()}>
          <CloseIcon style={{ color: '#fffc' }} />
        </ColorLink>
      </div>
    </div>
  );
};

const styleImage = {
  width: 150,
  height: 150,
  border: '1px solid silver',
};

const mapStateToProps = (state) => ({
  profile: state.user,
});

export default compose(withTranslation(), connect(mapStateToProps))(DialogAuthen);
