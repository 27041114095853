import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Avatar as AntAvatar } from 'antd';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { supportLanguage } from 'i18n';
import english from 'Assets/flags/english.png';
import vietnamese from 'Assets/flags/vietnamese.png';

const languages = {
  en: {
    name: 'English',
    flag: english,
  },
  vi: {
    name: 'Tiếng Việt',
    flag: vietnamese,
  },
};

const useStyles = makeStyles({
  selected: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    color: 'white',
  },
});

const LanguageSwitcher = ({ t, i18n }) => {
  const classes = useStyles();
  const [onSelectLanguage, setOnSelectLanguage] = useState(null);

  const handleClick = (event) => {
    setOnSelectLanguage(event.currentTarget);
  };

  const handleClose = () => {
    setOnSelectLanguage(null);
  };

  const changeLanguage = (lang) => {
    supportLanguage(lang);
    handleClose();
  };

  const languageSelections = Object.keys(languages).map((lang) => {
    return (
      <MenuItem
        classes={{ selected: classes.selected }}
        selected={i18n.language === lang}
        key={lang}
        onClick={() => {
          changeLanguage(lang);
        }}>
        <AntAvatar size={17} style={{ marginRight: 10 }} src={languages[lang].flag} />
        {languages[lang].name}
      </MenuItem>
    );
  });

  return (
    <div className='language-switcher-button'>
      <IconButton onClick={handleClick}>
        <AntAvatar size={24} src={languages[i18n.language].flag} />
      </IconButton>
      <Menu
        className='language-menu-container'
        anchorEl={onSelectLanguage}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={Boolean(onSelectLanguage)}
        onClose={handleClose}>
        {languageSelections}
      </Menu>
    </div>
  );
};

export default withTranslation()(LanguageSwitcher);
