import { authClient, client } from 'Services/clients';

const mfaAPI = '/api/v1/me/mfa';

const config = (request) => authClient.post(`${mfaAPI}/config`, { ...request });
const associate = (request) => authClient.post(`${mfaAPI}/associate`, { ...request });
const confirm = (request) => authClient.post(`${mfaAPI}/confirm`, { ...request });
const login = (payload) => client.post('/api/v1/mfa/login', { ...payload });
const triggerChallenge = (payload) => client.post('/api/v1/mfa/trigger-challenge', { ...payload });

export const mfaService = {
  config,
  associate,
  confirm,
  login,
  triggerChallenge
};
