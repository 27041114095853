import React from 'react';
import { Link, withStyles } from '@material-ui/core';
import { browserHistory } from 'Helper/history';

const CustomLink = ({ to, onClick, ...props }) => {
  const direct = () => {
    browserHistory.push({
      pathname: to.pathname,
      search: to.search,
    });
  };
  const handleClick = (event) => {
    event.preventDefault();

    if (onClick) onClick();
    else direct();
  };

  return <Link {...props} onClick={handleClick} underline='none' />;
};

const ColorLink = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
}))(CustomLink);

export default ColorLink;
