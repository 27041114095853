import { ActionType } from 'Redux/actions';

export default (
  state = {
    list: [],
  },
  action,
) => {
  switch (action.type) {
    case ActionType.GET_WEBAUTHN_CREDENTIAL_LIST:
      return { ...state, list: action.data };
    default:
      return { ...state };
  }
};
