import { client } from './clients';

const passwordlessAPI = '/api/v1/passwordless';

const login = (request) => client.post(`${passwordlessAPI}/login`, { ...request });
const sendCode = (request) => client.post(`${passwordlessAPI}/send`, { ...request });

export const passwordlessService = {
  login,
  sendCode,
};
