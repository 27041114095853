import { combineReducers } from 'redux';
import user from './user';
import client from './client';
import webauthnCredential from './webauthnCredential';
import oidc_context from './oidc_context';

export const reducers = combineReducers({
  user,
  client,
  webauthnCredential,
  oidc_context
});
