import { ActionType } from 'Redux/actions';

export default (
  state = {
    name: ' ',
  },
  action,
) => {
  switch (action.type) {
    case ActionType.GET_ME:
      return { ...action.data };
    default:
      return { ...state };
  }
};
