import { bigIntAuthClient } from 'Services/clients';

const webauthnCredentialAPI = '/api/v1/webauthn-credentials';

const getListWebAuthnCredentials = () => bigIntAuthClient.get(`${webauthnCredentialAPI}/me`);
const getWebAuthnCredential = ({ id }) => bigIntAuthClient.get(`${webauthnCredentialAPI}/me/${id}`);
const getRegistrationOptions = () => bigIntAuthClient.get(`${webauthnCredentialAPI}/register`);
const registerWebAuthnCredential = ({ challenge, ...request }) =>
    bigIntAuthClient.post(`${webauthnCredentialAPI}/register`, { ...request }, { params: { challenge: challenge } });

export const webauthnService = {
  getListWebAuthnCredentials,
  getWebAuthnCredential,
  getRegistrationOptions,
  registerWebAuthnCredential
};