import React, { Fragment, useMemo } from 'react';
import { Button, Divider, FormControl, TextField, Typography } from '@material-ui/core';
import { validator } from '../Helper/validate';
import { Alert, ColorLink, Loading } from '../Components';
import { PublicRouter } from '../Navigation/Router';
import { browserHistory, redirect } from 'Helper/history';
import { compose } from 'recompose';
import { withPermission } from '../Containers/ScreenPermission';
import { CLIENT_PERMISSIONS as P } from '../Containers/PublicLayout';
import { withTranslation } from 'react-i18next';
import { RecaptchaLayout } from '../Containers/RecaptchaLayout';

const PasswordComingExpire = ({ t, challenge, client }) => {
  const { data = {}, isExpired = false } = browserHistory.location;
  // const dayLeft = data.passwordWarning.message.match(/\d+/)[0];
  const buttonColor = useMemo(() => {
    const buttonColor = client && client.theme && client.theme.buttonColor;
    return validator.isValidColor(buttonColor) ? buttonColor : undefined;
  }, [client]);
  const handleResetPassword = () => {
    browserHistory.push({
      pathname: PublicRouter.PasswordRecover.url,
      search: `challenge=${challenge}`,
      data: data,
    });
  };

  const handleContinueLogin = () => {
    if (Object.keys(data).length !== 0) {
      const { mfaRequired, tokenType, mfaToken, redirectTo, username } = data;
      if (mfaRequired) {
        browserHistory.push({
          pathname: PublicRouter.Login2Step.url,
          search: `challenge=${challenge}`,
          state: {
            tokenType: tokenType,
            mfaToken: mfaToken,
            username: username,
            challenge: challenge,
          },
        });
      } else if (redirectTo) {
        redirect(redirectTo);
      } else {
        Alert.error(t('Sorry, something went wrong there'));
      }
    } else {
      browserHistory.replace({
        pathname: PublicRouter.Login.url,
        search: `challenge=${challenge}`,
      });
    }
  };
  return (
    <div>
      {
        isExpired ?
          <Fragment>
            <Typography variant='h5' className='mb-36'>
              {t('Password expiring early')}
            </Typography>
            <FormControl fullWidth className='mb-24'>
              <Button color='primary' onClick={() => handleResetPassword()} variant='contained'>
                <Loading /> {t('Reset password')}
              </Button>
            </FormControl>
          </Fragment>
          :
          <Fragment>
            <Typography variant='h5' className='mb-36'>
              {t('Password expiring early')}
            </Typography>
            <FormControl fullWidth className='mb-12'>
              <Button variant='contained' onClick={() => handleResetPassword()}>
                <Loading /> {t('Reset password')}
              </Button>
            </FormControl>
            <FormControl fullWidth className='mb-12'>
              <Button color='primary' style={{ color: buttonColor }} onClick={() => handleContinueLogin()}
                      variant='contained'>
                <Loading /> {t('Continue login')}
              </Button>
            </FormControl>
          </Fragment>
      }
    </div>
  );
};
export default compose(withPermission([P.PASSWORD_RESET]), withTranslation(), RecaptchaLayout())(PasswordComingExpire);
