import React, { Fragment, useMemo, useState, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { withPermission } from 'Containers/ScreenPermission';
import { RecaptchaLayout } from 'Containers/RecaptchaLayout';
import { compose } from 'recompose';
import { CLIENT_PERMISSIONS as P } from 'Containers/PublicLayout';
import { Typography, FormControl, TextField, Divider, Button } from '@material-ui/core';
import { Loading, ColorLink } from 'Components';
import { passwordService } from 'Services/password';
import { browserHistory } from 'Helper/history';
import { validator } from 'Helper/validate';
import { PublicRouter } from 'Navigation/Router';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const PasswordRecover = ({ t, challenge, client }) => {
  const { tenant } = client;
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const { buttonColor } = useMemo(() => {
    const { buttonColor } = client.theme || {};
    return {
      buttonColor: validator.isValidColor(buttonColor) ? buttonColor : undefined,
    };
  }, [client]);

  const isValidate = () => {
    let isError = false;
    if (!validator.isValidUsername(username)) {
      setUsernameError(true);
      isError = true;
    }
    return !isError;
  };

  const recaptchaEnabled = (typeof client.recaptchaConfiguration === 'object' && client.recaptchaConfiguration) ? client.recaptchaConfiguration.enabled : false;
  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleClickSubmit = useCallback(async () => {
    if (recaptchaEnabled && !executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const recaptchaToken = recaptchaEnabled ? await executeRecaptcha('getCaptchaToken') : '';
    if (isValidate()) {
      const request = {
        username,
        recaptchaToken,
      };
      setIsLoading(true);
      passwordService
        .recoverPassword(request)
        .then((response) => {
          browserHistory.push({
            pathname: PublicRouter.PasswordUpdate.url,
            search: `challenge=${challenge}`,
            state: { username },
          });
        })
        .catch(() => {
        })
        .then(() => setIsLoading(false));
    }
  }, [executeRecaptcha, username]);

  const handlePressKey = (event) => {
    if (event.key === 'Enter') {
      handleClickSubmit();
    }
  };

  return (
    <Fragment>
      <Typography variant='h5' className='mb-36'>
        {t('Forgot/Reset password')}
      </Typography>
      <div className='flex-center my-12'>
        <Divider className='flex-1' />
        <span className='mx-12 color-gray text-bolder'>{t('Enter your email or phone number')}</span>
        <Divider className='flex-1' />
      </div>
      <FormControl fullWidth className='mb-12'>
        <TextField
          label={t('Email or phone number')}
          margin='dense'
          variant='outlined'
          value={username}
          error={usernameError}
          onChange={(event) => {
            const { value } = event.target;
            setUsername(value);
            setUsernameError(!validator.isValidUsername(value));
          }}
          onKeyPress={(e) => handlePressKey(e)}
        />
      </FormControl>
      <div className='mb-24' />
      <FormControl fullWidth className='mb-24'>
        <Button color='primary' style={{ color: buttonColor }} variant='contained' onClick={() => handleClickSubmit()}>
          <Loading visible={isLoading} /> {t('Continue')}
        </Button>
      </FormControl>
      <FormControl fullWidth className='mb-12'>
        <Typography variant='body1' className='justify-content-end'>
          <ColorLink
            to={{
              pathname: PublicRouter.Login.url,
              search: `challenge=${challenge}`,
            }}
          >
            {t('Login')}
          </ColorLink>
        </Typography>
      </FormControl>
    </Fragment>
  );
};

export default compose(withPermission([P.PASSWORD_RESET]), withTranslation(), RecaptchaLayout())(PasswordRecover);
