import React, { Fragment, useState, useEffect, useMemo, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { withPermission } from 'Containers/ScreenPermission';
import { RecaptchaLayout } from 'Containers/RecaptchaLayout';
import { compose } from 'recompose';
import { CLIENT_PERMISSIONS as P } from 'Containers/PublicLayout';
import {
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  FormControl,
  TextField,
  Divider,
  Button,
} from '@material-ui/core';
import { Alert, Loading, ColorLink, PasswordPolicyTooltip } from 'Components';
import { AccountCircleIcon, ArrowBackIcon } from 'Components/Icons';
import { passwordService } from 'Services/password';
import { browserHistory } from 'Helper/history';
import { validator } from 'Helper/validate';
import { t as tr } from 'Helper/translator';
import { PublicRouter } from 'Navigation/Router';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const PasswordUpdate = ({ t, challenge, client }) => {
  const [username, setUsername] = useState('');
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordMatchError, setPasswordMatchError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPasswordPolicy, setLoadingPasswordPolicy] = useState(false);
  const [passwordPolicy, setPasswordPolicy] = useState(validator.defaultPasswordPolicy);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoadingCode, setIsLoadingCode] = useState(false);
  const [counter, setCounter] = useState(0);

  const { buttonColor } = useMemo(() => {
    const { buttonColor } = client.theme || {};
    return {
      buttonColor: validator.isValidColor(buttonColor) ? buttonColor : undefined,
    };
  }, [client]);

  const isValidate = () => {
    let isError = false;
    if (!validator.isValidCode(code)) {
      setCodeError(true);
      isError = true;
    }
    if (!validator.isValidPassword(password, passwordPolicy)) {
      setPasswordError(true);
      isError = true;
    }

    if (password !== passwordConfirm) {
      setPasswordMatchError(true);
      isError = true;
    }
    return !isError;
  };

  const handleToggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const recaptchaEnabled = (typeof client.recaptchaConfiguration === 'object' && client.recaptchaConfiguration) ? client.recaptchaConfiguration.enabled : false;
  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleClickSubmit = useCallback(async () => {
    if (recaptchaEnabled && !executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const recaptchaToken = recaptchaEnabled ? await executeRecaptcha('getCaptchaToken') : '';
    if (isValidate()) {
      const request = {
        username,
        password,
        code,
        recaptchaToken
      };
      setIsLoading(true);
      passwordService
        .resetPassword(request)
        .then((response) => {
          Alert.success(t('Update Password successfully'));

          browserHistory.push({
            pathname: PublicRouter.Login.url,
            search: `challenge=${challenge}`,
          });
        })
        .catch(() => {})
        .then(() => setIsLoading(false));
    }
  }, [executeRecaptcha, username, password, passwordConfirm, code]);

  const resendRecoveryCode = useCallback(async () => {
    if (counter > 0) return;

    if (recaptchaEnabled && !executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const recaptchaToken = recaptchaEnabled ? await executeRecaptcha('getCaptchaToken') : '';
    const request = { username, recaptchaToken };
    setIsLoadingCode(true);
    passwordService
      .recoverPassword(request)
      .then((response) => {
        const message = validator.isEmail({ username }) ? t('Please check your email') : t('Please check your phone');
        Alert.success(message);

        setCounter(60);
      })
      .catch(() => {})
      .then(() => setIsLoadingCode(false));
  }, [executeRecaptcha, username]);

  const handlePressKey = (event) => {
    if (event.key === 'Enter') {
      handleClickSubmit();
    }
  };

  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => {
        setCounter((counter) => counter - 1);
      }, 1000);
    }
  }, [counter]);

  useEffect(() => {
    setLoadingPasswordPolicy(true);
    passwordService
      .getPasswordPolicy({ tenantId: client.tenantId })
      .then((res) => {
        setPasswordPolicy(res);
      })
      .catch(() => {})
      .finally(() => {
        setLoadingPasswordPolicy(false);
      });
  }, [client, client.tenantId]);

  const { state = {} } = browserHistory.location;

  useEffect(() => {
    if (state.username) {
      const isEmail = validator.isEmail(state);
      const message = isEmail ? tr('Please check your email') : tr('Please check your phone');
      Alert.success(message);
      setUsername(state.username);
    } else {
      browserHistory.replace({ pathname: PublicRouter.Error.url, state: 500 });
    }
  }, [state]);

  return (
    <Fragment>
      <Typography variant='h5' className='mb-36'>
        {t('Change password')}
      </Typography>
      <FormControl fullWidth className='mb-12'>
        <TextField
          label={t('Account')}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position='end'>
                <AccountCircleIcon />
              </InputAdornment>
            ),
          }}
          margin='dense'
          variant='outlined'
          value={state.username}
        />
      </FormControl>
      <div className='flex-center my-12'>
        <Divider className='flex-1' />
        <span className='mx-12 color-gray text-bolder'>{t('Enter the verification code')}</span>
        <Divider className='flex-1' />
      </div>
      <FormControl fullWidth className='mb-12'>
        <TextField
          autoFocus
          label={t('Recovery code')}
          margin='dense'
          variant='outlined'
          value={code}
          error={codeError}
          onChange={(event) => {
            const { value } = event.target;
            setCode(value);
            setCodeError(!validator.isValidCode(value));
          }}
          onKeyPress={(e) => handlePressKey(e)}
        />
      </FormControl>
      <FormControl fullWidth className='mb-12'>
        <TextField
          label={t('New password')}
          margin='dense'
          variant='outlined'
          type={showPassword ? 'text' : 'password'}
          value={password}
          error={passwordError || passwordMatchError}
          onChange={(event) => {
            const { value } = event.target;
            setPassword(value);
            setPasswordError(!validator.isValidPassword(value, passwordPolicy));
          }}
          onKeyPress={(e) => handlePressKey(e)}
          InputProps={{
            endAdornment: isLoadingPasswordPolicy ? (
              <InputAdornment position='end' className='color-gray'>
                <Loading visible={true} />
              </InputAdornment>
            ) : (
              <PasswordPolicyTooltip passwordPolicy={passwordPolicy} />
            ),
          }}
        />
      </FormControl>
      <FormControl fullWidth className='mb-12'>
        <TextField
          label={t('Confirm password')}
          margin='dense'
          variant='outlined'
          type={showPassword ? 'text' : 'password'}
          value={passwordConfirm}
          error={passwordMatchError}
          onChange={(event) => {
            const { value } = event.target;
            setPasswordConfirm(value);
            setPasswordMatchError(password !== value);
          }}
          onKeyPress={(e) => handlePressKey(e)}
        />
      </FormControl>
      <FormGroup>
        <FormControlLabel
          className='mb-6'
          control={
            <Checkbox checked={showPassword} onChange={handleToggleShowPassword} name='showPassword' color='primary' />
          }
          label={<span className='color-gray'>{t('Show password')}</span>}
        />
      </FormGroup>
      <FormControl fullWidth>
        <Typography component='div' variant='body2' className='justify-content-center'>
          <ColorLink className='align-items-center' disabled={counter > 0} onClick={() => resendRecoveryCode()}>
            <Loading visible={isLoadingCode} />
            {counter > 0 && <span style={{ marginRight: 8 }}>({counter})</span>}
            {t('Resend recovery code')}
          </ColorLink>
        </Typography>
      </FormControl>
      <div className='mb-24' />
      <FormControl fullWidth className='mb-24'>
        <Button color='primary' style={{ color: buttonColor }} variant='contained' onClick={() => handleClickSubmit()}>
          <Loading visible={isLoading} /> {t('Update password')}
        </Button>
      </FormControl>
      <FormControl fullWidth className='mb-12'>
        <Typography variant='body1' className='justify-content-end'>
          <ColorLink
            to={{
              pathname: PublicRouter.Login.url,
              search: `challenge=${challenge}`,
            }}
          >
            {t('Login')}
          </ColorLink>
        </Typography>
      </FormControl>
      <div style={{ position: 'absolute', top: 32, cursor: 'pointer' }} title={t('Back')}>
        <ColorLink
          onClick={() => {
            browserHistory.goBack();
          }}
        >
          <ArrowBackIcon />
        </ColorLink>
      </div>
    </Fragment>
  );
};

export default compose(
  withPermission([P.PASSWORD_RESET]),
  withTranslation(),
  RecaptchaLayout()
)(PasswordUpdate);
